import axios from "axios"
import { useCallback, useEffect, useState } from "react"
import Select from 'react-select'
import { editarContext } from "../../../context/Editar";
import { useContext } from "react";
import { vencimientoContext } from "../../../context/Vencimiento";
import { useCookies } from 'react-cookie';
export default function SeccionClienteEditar({ expediente }) {
    const [productores, setProductores] = useState([])
    // carga pas
    useEffect(() => {
        axios.get("https://api.tuveunchoque.com.ar/api/nuevosUsuarios/pasParaFiltros").then((res) => {
            setProductores(res.data);
        });
    }, []);
    // inicia datos acorde a la data del exp
    const [clienteData, setClienteData] = useState({
        nombre: expediente.nombre,
        nombreConductor: expediente.nombreConductorCliente,
        dominio: expediente.dominioCliente,
        pas: expediente.productorAsociado,
        honorarios: expediente.honorarios,
        marca: expediente.marcaModeloCliente,
        tel: expediente.telefono,
        dni: expediente.dni,
        mail: expediente.mail,
        tel2: expediente.telefono2,
        tel3: expediente.telefono3
    })
    const [cookies] = useCookies(['user']);
    const [carga, setCarga] = useState(false)
    const hoy = new Date().toISOString().split('T')[0];
    const { vencimiento, } = useContext(vencimientoContext)
    // funcion al back
    const clienteDataUpdate = useCallback(async (e) => {
        e.preventDefault()
        await axios.post(`https://api.tuveunchoque.com.ar/api/gerencia/cliente/update`,
            {
                id: expediente.id,
                nombre: clienteData.nombre,
                nombreConductor: clienteData.nombreConductor,
                dominio: clienteData.dominio,
                pas: clienteData.pas,
                honorarios: clienteData.honorarios,
                marca: clienteData.marca,
                tel: clienteData.tel,
                dni: clienteData.dni,
                mail: clienteData.mail,
                tel2: clienteData.tel2,
                tel3: clienteData.tel3,
                ultMod: hoy,
                vencimiento: new Date(vencimiento).toLocaleDateString('fr-FR'),
                modificadoPor: cookies.user.nombre,
                esNovedades: expediente.asignadoA !== cookies.user.nombre ? 1 : 0
            }).then((response) => {
                setCarga(true)
                if (response) {
                    setTimeout(async () => {
                        window.location.reload()
                    }, 1000);
                }
            })
    }, [clienteData, vencimiento, expediente])
    const { setEditarFalse } = useContext(editarContext)
    const hnrsPas = clienteData.pas === 'PRADAL' || clienteData.pas === 'RAMIREZ' || clienteData.pas === 'SOKALSKI' || clienteData.pas === 'FLORES FLAVIA'
    function dom1() {
        if (hnrsPas && clienteData.honorarios < 5) {
            return <h6 className="text-danger center">{`El monto minimo para el pas ${clienteData.pas} perteneciente al grupo ABRA es del 5%`}</h6>
        }
    }
    return (
        <div>
            <div className="pe-3 ps-3">
                <form className="mt-1" onSubmit={clienteDataUpdate}>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Nombre Cliente</label>
                            <input type="text" class="form-control" value={clienteData.nombre}
                                onChange={(e) =>
                                    setClienteData((prevState) => ({
                                        ...prevState,
                                        nombre: e.target.value,
                                    }))} />
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Nombre Conductor del Cliente</label>
                            <input type="text" class="form-control" value={clienteData.nombreConductor}
                                onChange={(e) =>
                                    setClienteData((prevState) => ({
                                        ...prevState,
                                        nombreConductor: e.target.value,
                                    }))} />
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Dominio</label>
                            <input type="text" class="form-control" value={clienteData.dominio}
                                onChange={(e) =>
                                    setClienteData((prevState) => ({
                                        ...prevState,
                                        dominio: e.target.value,
                                    }))} />
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Pas Asociado</label>
                            <Select
                                options={productores.sort((a, b) => a.nombre.localeCompare(b.nombre)).map(pro => ({ value: pro.nombre, label: pro.nombre }))}
                                placeholder={expediente.productorAsociado}
                                onChange={(newValue) => setClienteData((prevState) => ({
                                    ...prevState,
                                    pas: newValue.value,
                                }))}
                            />
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Honorarios</label>
                            <input type="text" class="form-control" value={clienteData.honorarios}
                                onChange={(e) =>
                                    setClienteData((prevState) => ({
                                        ...prevState,
                                        honorarios: e.target.value,
                                    }))} />
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">Marca/Modelo Cliente</label>
                            <input type="text" class="form-control" value={clienteData.marca}
                                onChange={(e) =>
                                    setClienteData((prevState) => ({
                                        ...prevState,
                                        marca: e.target.value,
                                    }))} />
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Mail</label>
                            <input type="mail" class="form-control" value={clienteData.mail}
                                onChange={(e) =>
                                    setClienteData((prevState) => ({
                                        ...prevState,
                                        mail: e.target.value,
                                    }))} />
                        </div>
                        <div class="col">
                            <label class="mb-1 letraPlanillaExpediente">DNI</label>
                            <input type="text" class="form-control" value={clienteData.dni}
                                onChange={(e) =>
                                    setClienteData((prevState) => ({
                                        ...prevState,
                                        dni: e.target.value,
                                    }))} />
                        </div>
                    </div>
                    <div class="mb-2 row">
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Telefono</label>
                            <input type="tel" class="form-control" value={clienteData.tel}
                                onChange={(e) =>
                                    setClienteData((prevState) => ({
                                        ...prevState,
                                        tel: e.target.value,
                                    }))} />
                        </div>
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Telefono 2</label>
                            <input type="tel" class="form-control" value={clienteData.tel2}
                                onChange={(e) =>
                                    setClienteData((prevState) => ({
                                        ...prevState,
                                        tel2: e.target.value,
                                    }))} />
                        </div>
                        <div className="col">
                            <label class="mb-1 letraPlanillaExpediente">Telefono 3</label>
                            <input type="tel" class="form-control" value={clienteData.tel3}
                                onChange={(e) =>
                                    setClienteData((prevState) => ({
                                        ...prevState,
                                        tel3: e.target.value,
                                    }))} />
                        </div>
                    </div>
                    <div className="d-flex justify-content-center mt-4 mb-3">
                        <button type="submit" className="btn-primary btn" disabled={!vencimiento ? true : (hnrsPas && clienteData.honorarios < 5 ? true : false)}>Actualizar</button>
                        <button className="btn btn-secondary btn-sm ms-5" onClick={setEditarFalse}>Cancelar</button>
                    </div>
                    {!vencimiento ? <div className="center"><p className="text-danger">Se necesita actualizar la fecha de vencimiento</p></div> : <div></div>}
                    <p>{dom1()}</p>
                    {carga ?
                        <div className="center">
                            <div class="spinner-border text-primary center" role="status">
                                <span class="visually-hidden center">Loading...</span>
                            </div>
                        </div> : <div></div>}
                </form>
            </div>
        </div>
    )
}