import './detalles.css'
import axios from 'axios';
import { useCallback } from 'react';
import { useCookies } from 'react-cookie';
import esrever from 'esrever'
export default function Mensaje(mensaje, nro) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    let clas = ''
    let clas1 = ''
    let clas2 = ''
    let clas3 = ''
    // si el estado del mensaje es mediacion, el cargo es empleado o agente
    if (mensaje.mensaje['ESTADO'] === 'MEDIACION' && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
        clas = 'mediacionEmpleado'
        clas1 = 'userTextDetalleEmpleado2 m-0'
        clas2 = 'mensajeDetalleEmpleado2 m-0'
        clas3 = 'horaDetalleEmpleado m-0'
    } else if (mensaje.mensaje['ESTADO'] === 'MEDIACION' && mensaje.mensaje['CARGO'] === 'MEDIADOR') {
        clas = 'mediacionEmpleado'
        clas1 = 'userTextDetalleEmpleado2 m-0'
        clas2 = 'mensajeDetalleEmpleado2 m-0'
        clas3 = 'horaDetalleEmpleado m-0'
    } else if (mensaje.mensaje['ESTADO'] === 'MEDIACION' && mensaje.mensaje['CARGO'] === 'GERENCIA') {
        clas = 'mediacionGerencia'
        clas1 = 'userTextDetalleGerencia2 m-0'
        clas2 = 'mensajeDetalleGerencia2 m-0'
        clas3 = 'horaDetalleGerencia m-0'
    } else if (mensaje.mensaje['ANCLADO'] && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
        clas = 'ancladoEmpleado'
        clas1 = 'userTextDetalleEmpleado2 m-0'
        clas2 = 'mensajeDetalleEmpleado2 m-0'
        clas3 = 'horaDetalleEmpleado m-0'
    } else if (mensaje.mensaje['ANCLADO'] && mensaje.mensaje['CARGO'] === 'GERENCIA') {
        clas = 'ancladoGerencia'
        clas1 = 'userTextDetalleGerencia2 m-0'
        clas2 = 'mensajeDetalleGerencia2 m-0'
        clas3 = 'horaDetalleGerencia m-0'
    } else if (mensaje.mensaje['OFRECIMIENTO'] && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
        clas = 'ofrecimientoEmpleado'
        clas1 = 'userTextDetalleEmpleado2 m-0'
        clas2 = 'mensajeDetalleEmpleado2 m-0'
        clas3 = 'horaDetalleEmpleado m-0'
    } else if (mensaje.mensaje['OFRECIMIENTO'] && mensaje.mensaje['CARGO'] === 'GERENCIA') {
        clas = 'ofrecimientoGerencia'
        clas1 = 'userTextDetalleGerencia2 m-0'
        clas2 = 'mensajeDetalleGerencia2 m-0'
        clas3 = 'horaDetalleGerencia m-0'
    } else if (mensaje.mensaje['PRIVADO'] && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
        clas = 'privadoEmpleado'
        clas1 = 'userTextDetalleEmpleado2 m-0'
        clas2 = 'mensajeDetalleEmpleado2 m-0'
        clas3 = 'horaDetalleEmpleado m-0'
    } else if (mensaje.mensaje['PRIVADO'] && mensaje.mensaje['CARGO'] === 'GERENCIA') {
        clas = 'privadoGerencia'
        clas1 = 'userTextDetalleGerencia2 m-0'
        clas2 = 'mensajeDetalleGerencia2 m-0'
        clas3 = 'horaDetalleGerencia m-0'
    } else if ((mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
        clas = 'empleado'
        clas1 = 'userTextDetalleEmpleado m-0'
        clas2 = 'mensajeDetalleEmpleado m-0'
        clas3 = 'horaDetalleEmpleado m-0'
    } else if (mensaje.mensaje['CARGO'] === 'GERENCIA') {
        clas = 'gerencia'
        clas1 = 'userTextDetalleGerencia m-0'
        clas2 = 'mensajeDetalleGerencia m-0'
        clas3 = 'horaDetalleGerencia m-0'
    } else {
        clas = ''
    }
const b = esrever.reverse((JSON.stringify(mensaje.mensaje['FECHA']).replace(/\"/g, ""))).slice(3)
const c = esrever.reverse(b)
const id = mensaje.mensaje.ID
const borrarMensaje = useCallback((e) => {
    e.preventDefault()
    axios.post('https://api.tuveunchoque.com.ar/api/gerencia/detalles/visible', { id })
        .then(response => {
            window.location.reload()
        })
        .catch(error => {
            console.error('Error al borrar el mensaje:', error);
        });
}, [id]);
return (
    <>
        {mensaje.mensaje['VISIBLE'] && (cookies.user.cargo === 'PAS' || cookies.user.cargo === 'GRUPO' || cookies.user.cargo === 'ORGANIZACION') ?
            <div className='mt-2'>
                {mensaje.mensaje['ANCLADO'] === true ? <div></div> : <div>
                    <div className={clas}>
                        <p className={clas1}>{mensaje.mensaje['USUARIO']}</p>
                        <p className={clas2}>{JSON.stringify(mensaje.mensaje['OBSERVACIONES']).toLowerCase().replace(/\"/g, "").replace(/\\n/g, " ")}</p>
                    </div>
                    <div className='d-flex'>
                        <p className={clas3}>{c} - {mensaje.mensaje['ESTADO']}</p>
                    </div>
                </div>}
            </div> :
            (mensaje.mensaje['VISIBLE'] ?
                <div className='mt-2'>
                    <div className={clas}>
                        <p className={clas1}>{mensaje.mensaje['USUARIO']}</p>
                        <p className={clas2}>{JSON.stringify(mensaje.mensaje['OBSERVACIONES']).toLowerCase().replace(/\"/g, "").replace(/\\n/g, " ")}</p>
                    </div>
                    <div className='d-flex gap-3'>
                        <p className={clas3}>{c} - {mensaje.mensaje['ESTADO']}</p>
                        {cookies.user.cargo === 'GERENCIA' ? <button onClick={(e) => borrarMensaje(e)} style={{ border: 'none', background: 'none' }} className='center'><i class="bi bi-trash3" style={{ fontSize: '12px' }}></i></button> : <></>}
                    </div>
                </div>
                : <></>
            )
        }
    </>
)
}


/*if (nro.nro < 100) {
        if (mensaje.mensaje['ESTADO'] === 'MEDIACION' && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
            clas = 'mediacionEmpleado'
            clas1 = 'userTextDetalleEmpleado2 m-0'
            clas2 = 'mensajeDetalleEmpleado2 m-0'
            clas3 = 'horaDetalleEmpleado m-0'
        } else if (mensaje.mensaje['ESTADO'] === 'MEDIACION' && mensaje.mensaje['CARGO'] === 'MEDIADOR') {
            clas = 'mediacionEmpleado'
            clas1 = 'userTextDetalleEmpleado2 m-0'
            clas2 = 'mensajeDetalleEmpleado2 m-0'
            clas3 = 'horaDetalleEmpleado m-0'
        } else if (mensaje.mensaje['ESTADO'] === 'MEDIACION' && mensaje.mensaje['CARGO'] === 'GERENCIA') {
            clas = 'mediacionGerencia'
            clas1 = 'userTextDetalleGerencia2 m-0'
            clas2 = 'mensajeDetalleGerencia2 m-0'
            clas3 = 'horaDetalleGerencia m-0'
        } else if (mensaje.mensaje['ANCLADO'] && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
            clas = 'ancladoEmpleado'
            clas1 = 'userTextDetalleEmpleado2 m-0'
            clas2 = 'mensajeDetalleEmpleado2 m-0'
            clas3 = 'horaDetalleEmpleado m-0'
        } else if (mensaje.mensaje['ANCLADO'] && mensaje.mensaje['CARGO'] === 'GERENCIA') {
            clas = 'ancladoGerencia'
            clas1 = 'userTextDetalleGerencia2 m-0'
            clas2 = 'mensajeDetalleGerencia2 m-0'
            clas3 = 'horaDetalleGerencia m-0'
        } else if (mensaje.mensaje['OFRECIMIENTO'] && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
            clas = 'ofrecimientoEmpleado'
            clas1 = 'userTextDetalleEmpleado2 m-0'
            clas2 = 'mensajeDetalleEmpleado2 m-0'
            clas3 = 'horaDetalleEmpleado m-0'
        } else if (mensaje.mensaje['OFRECIMIENTO'] && mensaje.mensaje['CARGO'] === 'GERENCIA') {
            clas = 'ofrecimientoGerencia'
            clas1 = 'userTextDetalleGerencia2 m-0'
            clas2 = 'mensajeDetalleGerencia2 m-0'
            clas3 = 'horaDetalleGerencia m-0'
        } else if (mensaje.mensaje['PRIVADO'] && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
            clas = 'privadoEmpleado'
            clas1 = 'userTextDetalleEmpleado2 m-0'
            clas2 = 'mensajeDetalleEmpleado2 m-0'
            clas3 = 'horaDetalleEmpleado m-0'
        } else if (mensaje.mensaje['PRIVADO'] && mensaje.mensaje['CARGO'] === 'GERENCIA') {
            clas = 'privadoGerencia'
            clas1 = 'userTextDetalleGerencia2 m-0'
            clas2 = 'mensajeDetalleGerencia2 m-0'
            clas3 = 'horaDetalleGerencia m-0'
        } else if ((mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
            clas = 'empleado'
            clas1 = 'userTextDetalleEmpleado m-0'
            clas2 = 'mensajeDetalleEmpleado m-0'
            clas3 = 'horaDetalleEmpleado m-0'
        } else if (mensaje.mensaje['CARGO'] === 'GERENCIA') {
            clas = 'gerencia'
            clas1 = 'userTextDetalleGerencia m-0'
            clas2 = 'mensajeDetalleGerencia m-0'
            clas3 = 'horaDetalleGerencia m-0'
        } else {
            clas = ''
        }
    } else {
        if (mensaje.mensaje['ESTADO'] === 'MEDIACION' && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
            clas = 'mediacionEmpleado'
            clas1 = 'userTextDetalleEmpleado2 m-0'
            clas2 = 'mensajeDetalleEmpleado2 m-0'
            clas3 = 'horaDetalleEmpleado m-0'
        } else if (mensaje.mensaje['ESTADO'] === 'MEDIACION' && mensaje.mensaje['CARGO'] === 'MEDIADOR') {
            clas = 'mediacionEmpleado'
            clas1 = 'userTextDetalleEmpleado2 m-0'
            clas2 = 'mensajeDetalleEmpleado2 m-0'
            clas3 = 'horaDetalleEmpleado m-0'
        } else if (mensaje.mensaje['ESTADO'] === 'MEDIACION' && mensaje.mensaje['CARGO'] === 'GERENCIA') {
            clas = 'mediacionGerencia'
            clas1 = 'userTextDetalleGerencia2 m-0'
            clas2 = 'mensajeDetalleGerencia2 m-0'
            clas3 = 'horaDetalleGerencia m-0'
        } else if (mensaje.mensaje['ANCLADO'] && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
            clas = 'ancladoEmpleado'
            clas1 = 'userTextDetalleEmpleado2 m-0'
            clas2 = 'mensajeDetalleEmpleado2 m-0'
            clas3 = 'horaDetalleEmpleado m-0'
        } else if (mensaje.mensaje['ANCLADO'] && mensaje.mensaje['CARGO'] === 'GERENCIA') {
            clas = 'ancladoGerencia'
            clas1 = 'userTextDetalleGerencia2 m-0'
            clas2 = 'mensajeDetalleGerencia2 m-0'
            clas3 = 'horaDetalleGerencia m-0'
        } else if (mensaje.mensaje['OFRECIMIENTO'] && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
            clas = 'ofrecimientoEmpleado'
            clas1 = 'userTextDetalleEmpleado2 m-0'
            clas2 = 'mensajeDetalleEmpleado2 m-0'
            clas3 = 'horaDetalleEmpleado m-0'
        } else if (mensaje.mensaje['OFRECIMIENTO'] && mensaje.mensaje['CARGO'] === 'GERENCIA') {
            clas = 'ofrecimientoGerencia'
            clas1 = 'userTextDetalleGerencia2 m-0'
            clas2 = 'mensajeDetalleGerencia2 m-0'
            clas3 = 'horaDetalleGerencia m-0'
        } else if (mensaje.mensaje['PRIVADO'] && (mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
            clas = 'privadoEmpleado'
            clas1 = 'userTextDetalleEmpleado2 m-0'
            clas2 = 'mensajeDetalleEmpleado2 m-0'
            clas3 = 'horaDetalleEmpleado m-0'
        } else if (mensaje.mensaje['PRIVADO'] && mensaje.mensaje['CARGO'] === 'GERENCIA') {
            clas = 'privadoGerencia'
            clas1 = 'userTextDetalleGerencia2 m-0'
            clas2 = 'mensajeDetalleGerencia2 m-0'
            clas3 = 'horaDetalleGerencia m-0'
        } else if ((mensaje.mensaje['CARGO'] === 'EMPLEADO' || mensaje.mensaje['CARGO'] === 'AGENTE')) {
            clas = 'empleadoViejo'
            clas1 = 'userTextDetalleEmpleado2 m-0'
            clas2 = 'mensajeDetalleEmpleado2 m-0'
            clas3 = 'horaDetalleEmpleado m-0'
        } else if (mensaje.mensaje['CARGO'] === 'GERENCIA') {
            clas = 'gerenciaViejo'
            clas1 = 'userTextDetalleGerencia2 m-0'
            clas2 = 'mensajeDetalleGerencia2 m-0'
            clas3 = 'horaDetalleGerencia m-0'
        } else {
            clas = ''
        }
    }*/