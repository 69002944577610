import { Link } from "react-router-dom"
import './expedientesTablas.css'
import { useCookies } from 'react-cookie';
import axios from 'axios'
import { useEffect, useState } from "react";
export default function Cobros({ expediente, }) {
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const link = { textDecoration: 'none' }
    const iconoMed = { background: 'yellow', height: '30px', width: '30px', borderRadius: '100%', color: 'black ' }
    const hora = localStorage.getItem('hora');
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const getTimeDifferenceInSeconds = (timeString1, timeString2) => {
        const [hours1, minutes1, seconds1] = timeString1.split(':').map(Number);
        const [hours2, minutes2, seconds2] = timeString2.split(':').map(Number);
        const date1 = new Date();
        date1.setHours(hours1, minutes1, seconds1, 0);
        const date2 = new Date();
        date2.setHours(hours2, minutes2, seconds2, 0);
        const differenceInMilliseconds = Math.abs(date1 - date2);
        return Math.floor(differenceInMilliseconds / 1000);
    };
    const registro = async (e) => {
        await axios.post('https://api.tuveunchoque.com.ar/api/registroDeUso/update', { user: cookies.user.nombre, segundos: getTimeDifferenceInSeconds(formatTime(time), hora) })
        localStorage.setItem('hora', formatTime(time))
        window.location.reload()
    }
    return (
        <>
            {window.innerWidth > 1920 ?
                <div className='containerTablaExpedientes mb-2'>
                    <div class="tituloExpedienteTabla1 d-flex w-100">
                        <Link onClick={(e) => registro(e)} to={`/expediente/${expediente.nro}`} style={link}>{expediente.nro}</Link>
                        {expediente.iconoMediacion === 1 ? <i class="bi bi-shield-fill-exclamation text-warning ms-1"></i> : <></>}
                    </div>
                    <div class="tituloExpedienteTabla2 d-flex w-100">{expediente.nroFactura}</div>  
                    <div class="tituloExpedienteTabla2 d-flex w-100">{expediente.capital}</div>
                    <div class="tituloExpedienteTabla2 d-flex w-100">{expediente.mesCobrado?.split("-").reverse().join("-")}</div>
                    <div class="tituloExpedienteTabla2 d-flex w-100">{expediente.mesEstimado?.split("-").reverse().join("-")}</div>
                    <div class="tituloExpedienteTabla2 d-flex w-100">{expediente.compania}</div>
                    <div class="tituloExpedienteTabla2 d-flex w-100">{expediente.honorariosFacturado}</div>
                    <div class="tituloExpedienteTabla2 d-flex w-100">{expediente.hnriosCliente}</div>
                    <div class="tituloExpedienteTabla2 d-flex w-100">{expediente.honorariosCobro}</div>
                    <div class="tituloExpedienteTabla2 d-flex w-100">{expediente.estadoCobro}</div>    
                </div>
                :
                <div className='containerTablaExpedientes mb-2'>
                    <div class="tituloExpedienteTabla11366 w-100 d-flex">
                        <Link onClick={(e) => registro(e)} to={`/expediente/${expediente.nro}`} style={link}>{expediente.nro}</Link>
                        {expediente.iconoMediacion === 1 ? <i class="bi bi-shield-fill-exclamation text-warning ms-1"></i> : <></>}
                    </div>
                    <div class="tituloExpedienteTabla21366 w-100 d-flex">{expediente.nroFactura}</div>
                    <div class="tituloExpedienteTabla21366 w-100 d-flex">{expediente.capital}</div>
                    <div class="tituloExpedienteTabla21366 w-100 d-flex">{expediente.mesCobrado?.split("-").reverse().join("-")}</div>
                    <div class="tituloExpedienteTabla21366 w-100 d-flex">{expediente.mesEstimado?.split("-").reverse().join("-")}</div>
                    <div class="tituloExpedienteTabla21366 w-100 d-flex">{expediente.compania}</div>
                    <div class="tituloExpedienteTabla21366 w-100 d-flex">{expediente.honorariosFacturado}</div>
                    <div class="tituloExpedienteTabla21366 w-100 d-flex">{expediente.hnriosCliente}</div>
                    <div class="tituloExpedienteTabla21366 w-100 d-flex">{expediente.honorariosCobro}</div>
                    <div class="tituloExpedienteTabla21366 w-100 d-flex">{expediente.estadoCobro}</div>
                </div>}
        </>
    )
}