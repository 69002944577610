import axios from "axios";
import { useCallback, useState, useContext, useEffect } from "react";
import { useCookies } from 'react-cookie';
import { useNavigate } from "react-router-dom";
import './login.css'
import este from '../images/logoBack.png'
import logo from '../images/logoTuveUnChoque.png'
import login2 from '../images/login2.jpg'
import banner from '../images/bannerLoginCliente.jpeg'
import { useMediaQuery } from 'react-responsive'
export default function Login() {
    const [signUpData, setSignUpData] = useState({ email: '', password: '' })
    const [cookies, setCookie, removeCookie] = useCookies(['user'], ['text'], ['ticket']);
    const navigate = useNavigate()
    const [infoTicket, setInfoTicket] = useState({ info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], prioridad: [], plazo: 5, expFrom: null, expTo: null });
    const [filtroNovedades, setFiltroNovedades] = useState({ info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], prioridad: [], expFrom: null, expTo: null });
    const [infoGestion, setInfoGestion] = useState({ info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], prioridad: [], plazo: 0, expFrom: null, expTo: null });
    const [infoTabla, setInfoTabla] = useState({ info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], patente: '', cliente: '', expFrom: '', expTo: '', estudioAsignado: [], mesCobradoFrom: '', mesCobradoTo: '' });
    const [infoTablaGerencia, setInfoTablaGerencia] = useState({ info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], vista: true, mesCobradoFrom: '', mesCobradoTo: '', mesEstimadoFrom: '', mesEstimadoTo: '', expFrom: '', expTo: '', modoDePago: '', estadoCobro: '', nroFactura: '', facturadoPor: '', honorariosFrom: '', honorariosTo: '', nroFrom: null, nroTo: null });
    const [userFalse, setUserFalse] = useState(false)
    const dia = new Date().toLocaleDateString('en-GB')
    const [time, setTime] = useState(new Date());
    useEffect(() => {
        const timerId = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(timerId);
    }, []);
    const formatTime = (date) => {
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };
    const userSignUpSubmit = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post("https://api.tuveunchoque.com.ar/api/nuevosUsuarios/log", { email: signUpData.email, password: signUpData.password })
        if (response.data === 'Invalid OAuth') {
            setUserFalse(true)
        } else {
            setCookie('user', response.data, { path: '/' })
            setCookie('ticket', JSON.stringify(infoTicket), { path: '/' })
            setCookie('filtroNovedades', JSON.stringify(filtroNovedades), { path: '/' })
            setCookie('tabla', JSON.stringify(infoTabla), { path: '/' })
            setCookie('exp', JSON.stringify([]), { path: '/' })
            setCookie('expMediacion', JSON.stringify([]), { path: '/' })
            setCookie('expNovedades', JSON.stringify([]), { path: '/' })
            setCookie('expActual', JSON.stringify([]), { path: '/' })
            setCookie('contadorExp', JSON.stringify(0), { path: '/' })
            setCookie('contadorExpMediacion', JSON.stringify(0), { path: '/' })
            setCookie('tablaGerencia', JSON.stringify(infoTablaGerencia), { path: '/' })
            setCookie('gestion', JSON.stringify(infoGestion), { path: '/' })
            localStorage.setItem('user', response.data.nombre);
            localStorage.setItem('dia', dia)
            localStorage.setItem('hora', formatTime(time))
            if (response.data.cargo === 'MEDIADOR') {
                navigate('/expedientes')
                removeCookie('text')
            } else if (response.data.cargo === 'PAS') {
                navigate('/expedientes')
                removeCookie('text')
            } else if (response.data.cargo === 'ORGANIZACION' || response.data.cargo === "GRUPO") {
                navigate('/expedientes')
                removeCookie('text')
            } else if (response.data.cargo === 'AGENTE') {
                navigate('/expedientes')
                removeCookie('text')
            } else {
                const res = await axios.post("https://api.tuveunchoque.com.ar/api/registroDeUso/sesion", { user: response.data.nombre, hora: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toString() })
                navigate('/novedadesNuevo')
                removeCookie('text')
            }
        }
    }, [signUpData, cookies, setCookie])
    const userSignUpSubmitMovil = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post("https://api.tuveunchoque.com.ar/api/nuevosUsuarios/log", { email: signUpData.email, password: signUpData.password })
        if (response.data === 'Invalid OAuth') {
            setUserFalse(true)
        } else {
            setCookie('user', response.data, { path: '/' })
            setCookie('ticket', JSON.stringify(infoTicket), { path: '/' })
            setCookie('filtroNovedades', JSON.stringify(filtroNovedades), { path: '/' })
            setCookie('tabla', JSON.stringify(infoTabla), { path: '/' })
            setCookie('exp', JSON.stringify([]), { path: '/' })
            setCookie('expMediacion', JSON.stringify([]), { path: '/' })
            setCookie('expNovedades', JSON.stringify([]), { path: '/' })
            setCookie('expActual', JSON.stringify([]), { path: '/' })
            setCookie('contadorExp', JSON.stringify(0), { path: '/' })
            setCookie('contadorExpMediacion', JSON.stringify(0), { path: '/' })
            setCookie('tablaGerencia', JSON.stringify(infoTablaGerencia), { path: '/' })
            setCookie('gestion', JSON.stringify(infoGestion), { path: '/' })
            localStorage.setItem('user', response.data.nombre);
            localStorage.setItem('dia', dia)
            localStorage.setItem('hora', formatTime(time))
            if (response.data.cargo === 'MEDIADOR') {
                navigate('/expedientes')
                removeCookie('text')
            } else if (response.data.cargo === 'PAS') {
                navigate('/expedientes')
                removeCookie('text')
            } else if (response.data.cargo === 'ORGANIZACION' || response.data.cargo === "GRUPO") {
                navigate('/expedientes')
                removeCookie('text')
            } else if (response.data.cargo === 'AGENTE') {
                navigate('/expedientes')
                removeCookie('text')
            } else {
                const res = await axios.post("https://api.tuveunchoque.com.ar/api/registroDeUso/sesion", { user: response.data.nombre, hora: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }).toString() })
                navigate('/expedientes')
                removeCookie('text')
            }
        }
    }, [signUpData, cookies, setCookie])
    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });
    return (
        /*<main className="mainLogin">
            <div class="image-container">
                <img src={login2} alt="Imagen recortada" />
            </div>
            <img src={logo} alt="" className="imgLogin" />
            <form onSubmit={userSignUpSubmit}>
                <div class="userLogin">
                    <input type="text" class="form-control" required value={signUpData.email} className="input w-100" placeholder="USUARIO"
                        onChange={(e) =>
                            setSignUpData((prevState) => ({
                                ...prevState,
                                email: e.target.value,
                            }))} />
                </div>
                <div class="passwordLogin">
                    <input type="password" class="form-control" required value={signUpData.password} className="input w-100" placeholder="CONTRASEÑA"
                        onChange={(e) =>
                            setSignUpData((prevState) => ({
                                ...prevState,
                                password: e.target.value,
                            }))} />
                </div>
                <div className={userFalse === false ? 'd-none' : 'userFalseLogin'}><p className="text-danger">Usuario y/o Contraseña incorrectos</p></div>
                <div className="submitLogin center"><button type="submit" className="btnSub text-white">Iniciar Sesion</button></div>
            </form>
        </main>*/
        <>
            {isMobile375 ?
                <main className="mainLogin">
                    <section className='m-auto mt-0'>
                        <img src={banner} alt="" style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', height: '30vh', width: '100%' }} />
                        <div className="center"><img src={logo} alt="" width={'60%'} height={'60px'} style={{ marginTop: '20px' }} /></div>
                        <form action="" className="center" onSubmit={userSignUpSubmitMovil} style={{ marginTop: '10vh' }}>
                            <div className='text-center'>
                                <label htmlFor="" className="labelLogin375 text-center">USUARIO</label>
                                <input type="text" className="inputPatenteLogin" required value={signUpData.email} onChange={(e) =>
                                    setSignUpData((prevState) => ({
                                        ...prevState,
                                        email: e.target.value,
                                    }))}
                                />
                                <label className='labelFechaStroLogin text-center'>CONTRASEÑA</label>
                                <div class="inputDateContainerLogin">
                                    <input type="text" class="inputPatenteLogin" required value={signUpData.password} onChange={(e) =>
                                        setSignUpData((prevState) => ({
                                            ...prevState,
                                            password: e.target.value,
                                        }))}
                                    />
                                </div>
                                <button type="submit" className='submitLoginNuevo375'>INGRESAR</button>
                            </div>
                        </form>
                    </section>
                </main>
                : (isMobile412 ?
                    <main className="mainLogin">
                        <section className='m-auto mt-0'>
                            <img src={banner} alt="" style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', height: '30vh', width: '100%' }} />
                            <div className="center"><img src={logo} alt="" width={'60%'} height={'60px'} style={{ marginTop: '20px' }} /></div>
                            <form action="" className="center" onSubmit={userSignUpSubmitMovil} style={{ marginTop: '10vh' }}>
                                <div className='text-center'>
                                    <label htmlFor="" className="labelLogin375 text-center">USUARIO</label>
                                    <input type="text" className="inputPatenteLogin" required value={signUpData.email} onChange={(e) =>
                                        setSignUpData((prevState) => ({
                                            ...prevState,
                                            email: e.target.value,
                                        }))}
                                    />
                                    <label className='labelFechaStroLogin text-center'>CONTRASEÑA</label>
                                    <div class="inputDateContainerLogin">
                                        <input type="text" class="inputPatenteLogin" required value={signUpData.password} onChange={(e) =>
                                            setSignUpData((prevState) => ({
                                                ...prevState,
                                                password: e.target.value,
                                            }))}
                                        />
                                    </div>
                                    <button type="submit" className='submitLoginNuevo375'>INGRESAR</button>
                                </div>
                            </form>
                        </section>
                    </main>
                    : (isMobile480 ?
                        <main className="mainLogin">
                            <section className='m-auto mt-0'>
                                <img src={banner} alt="" style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', height: '30vh', width: '100%' }} />
                                <div className="center"><img src={logo} alt="" width={'60%'} height={'60px'} style={{ marginTop: '20px' }} /></div>
                                <form action="" className="center" onSubmit={userSignUpSubmitMovil} style={{ marginTop: '10vh' }}>
                                    <div className='text-center'>
                                        <label htmlFor="" className="labelLogin375 text-center">USUARIO</label>
                                        <input type="text" className="inputPatenteLogin" required value={signUpData.email} onChange={(e) =>
                                            setSignUpData((prevState) => ({
                                                ...prevState,
                                                email: e.target.value,
                                            }))}
                                        />
                                        <label className='labelFechaStroLogin text-center'>CONTRASEÑA</label>
                                        <div class="inputDateContainerLogin">
                                            <input type="text" class="inputPatenteLogin" required value={signUpData.password} onChange={(e) =>
                                                setSignUpData((prevState) => ({
                                                    ...prevState,
                                                    password: e.target.value,
                                                }))}
                                            />
                                        </div>
                                        <button type="submit" className='submitLoginNuevo375'>INGRESAR</button>
                                    </div>
                                </form>
                            </section>
                        </main>
                        :
                        <main className="mainLogin">
                            <div class="image-container">
                                <img src={login2} alt="Imagen recortada" />
                            </div>
                            <img src={logo} alt="" className="imgLogin" />
                            <form onSubmit={userSignUpSubmit}>
                                <div class="userLogin">
                                    <input type="text" class="form-control" required value={signUpData.email} className="input w-100" placeholder="USUARIO"
                                        onChange={(e) =>
                                            setSignUpData((prevState) => ({
                                                ...prevState,
                                                email: e.target.value,
                                            }))} />
                                </div>
                                <div class="passwordLogin">
                                    <input type="password" class="form-control" required value={signUpData.password} className="input w-100" placeholder="CONTRASEÑA"
                                        onChange={(e) =>
                                            setSignUpData((prevState) => ({
                                                ...prevState,
                                                password: e.target.value,
                                            }))} />
                                </div>
                                <div className={userFalse === false ? 'd-none' : 'userFalseLogin'}><p className="text-danger">Usuario y/o Contraseña incorrectos</p></div>
                                <div className="submitLogin center"><button type="submit" className="btnSub text-white">Iniciar Sesion</button></div>
                            </form>
                        </main>
                    )
                )}
        </>
    );
}


/*<>
            {isMobile375 ?
                <main className="mainLogin">
                    <section className='m-auto mt-0'>
                        <img src={banner} alt="" style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', height: '30vh', width: '100%' }} />
                        <div className="center"><img src={logo} alt="" width={'60%'} height={'60px'} style={{ marginTop: '20px' }} /></div>
                        <form action="" className="center" onSubmit={userSignUpSubmit}>
                            <div className='text-center'>
                                <label htmlFor="" className="labelLogin375 text-center">USUARIO</label>
                                <input type="text" className="inputPatenteLogin" required value={signUpData.email} onChange={(e) =>
                                    setSignUpData((prevState) => ({
                                        ...prevState,
                                        email: e.target.value,
                                    }))}
                                />
                                <label className='labelFechaStroLogin text-center'>CONTRASEÑA</label>
                                <div class="inputDateContainerLogin">
                                    <input type="text" class="inputPatenteLogin" required value={signUpData.password} onChange={(e) =>
                                        setSignUpData((prevState) => ({
                                            ...prevState,
                                            password: e.target.value,
                                        }))}
                                    />
                                </div>
                                <button type="submit" className='submitLoginNuevo375'>INGRESAR</button>
                            </div>
                        </form>
                    </section>
                </main>
                : (isMobile412 ?
                    <main className="mainLogin">
                        <section className='m-auto mt-0'>
                            <img src={banner} alt="" style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', height: '30vh', width: '100%' }} />
                            <div className="center"><img src={logo} alt="" width={'60%'} height={'60px'} style={{ marginTop: '20px' }} /></div>
                            <form action="" className="center" onSubmit={userSignUpSubmit}>
                                <div className='text-center'>
                                    <label htmlFor="" className="labelLogin375 text-center">USUARIO</label>
                                    <input type="text" className="inputPatenteLogin" required value={signUpData.email} onChange={(e) =>
                                        setSignUpData((prevState) => ({
                                            ...prevState,
                                            email: e.target.value,
                                        }))}
                                    />
                                    <label className='labelFechaStroLogin text-center'>CONTRASEÑA</label>
                                    <div class="inputDateContainerLogin">
                                        <input type="text" class="inputPatenteLogin" required value={signUpData.password} onChange={(e) =>
                                            setSignUpData((prevState) => ({
                                                ...prevState,
                                                password: e.target.value,
                                            }))}
                                        />
                                    </div>
                                    <button type="submit" className='submitLoginNuevo375'>INGRESAR</button>
                                </div>
                            </form>
                        </section>
                    </main>
                    : (isMobile480 ?
                        <main className="mainLogin">
                            <section className='m-auto mt-0'>
                                <img src={banner} alt="" style={{ borderBottomLeftRadius: '20px', borderBottomRightRadius: '20px', height: '30vh', width: '100%' }} />
                                <div className="center"><img src={logo} alt="" width={'60%'} height={'60px'} style={{ marginTop: '20px' }} /></div>
                                <form action="" className="center" onSubmit={userSignUpSubmit}>
                                    <div className='text-center'>
                                        <label htmlFor="" className="labelLogin375 text-center">USUARIO</label>
                                        <input type="text" className="inputPatenteLogin" required value={signUpData.email} onChange={(e) =>
                                            setSignUpData((prevState) => ({
                                                ...prevState,
                                                email: e.target.value,
                                            }))}
                                        />
                                        <label className='labelFechaStroLogin text-center'>CONTRASEÑA</label>
                                        <div class="inputDateContainerLogin">
                                            <input type="text" class="inputPatenteLogin" required value={signUpData.password} onChange={(e) =>
                                                setSignUpData((prevState) => ({
                                                    ...prevState,
                                                    password: e.target.value,
                                                }))}
                                            />
                                        </div>
                                        <button type="submit" className='submitLoginNuevo375'>INGRESAR</button>
                                    </div>
                                </form>
                            </section>
                        </main>
                        :
                        <main className="mainLoginNuevo">
                            <section className="fotoMainClienteLadoIzqContenedor"><img src={banner} alt="" className='fotoMainClienteLadoIzq' /></section>
                            <section className='m-auto mt-0'>
                                <img src={logo} alt="" width={'400px'} height={'100px'} style={{ marginTop: '80px' }} />
                                <form action="" className="center" onSubmit={userSignUpSubmit}>
                                    <div className='text-center'>
                                        <label htmlFor="" className="labelLogin text-center">USUARIO</label>
                                        <input type="text" className="inputPatenteLogin" required value={signUpData.email} onChange={(e) =>
                                            setSignUpData((prevState) => ({
                                                ...prevState,
                                                email: e.target.value,
                                            }))}
                                        />
                                        <label className='labelFechaStroLogin text-center'>CONTRASEÑA</label>
                                        <div class="inputDateContainerLogin">
                                            <input type="text" class="inputPatenteLogin" required value={signUpData.password} onChange={(e) =>
                                                setSignUpData((prevState) => ({
                                                    ...prevState,
                                                    password: e.target.value,
                                                }))}
                                            />
                                        </div>
                                        <button type="submit" className='submitLoginNuevo'>INGRESAR</button>
                                    </div>
                                </form>
                            </section>
                        </main>
                    )
                )}
        </> */
