import './mediacion.css'
import Select from 'react-select';
import DatePicker, { registerLocale } from "react-datepicker";
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import axios from 'axios'
import { Link } from 'react-router-dom';
export default function PlanillaExpedienteMediacion({ expediente, }) {
    const [alert1, setAlert] = useState(true)
    //if (expediente.contadorNegociacionMediacion > 1 && alert1) {
    //alert(`La Etapa negociación ha sido activada ${expediente.contadorNegociacionMediacion} veces`)
    //setAlert(false)
    //}
    const [empleadoAsignado, setEmpleadoAsignado] = useState([])
    useEffect(() => {
        axios.get(`https://api.tuveunchoque.com.ar/api/mediacion/estudiosAsignadosMediacion`).then((res) => {
            setEmpleadoAsignado(res.data);
        });
    }, []);
    const solicitudDocumentalInput = ['SI', 'NO']
    const pagoGastosJudiciales = ['CLIENTE', 'PYP']
    const [etapa, setEtapa] = useState(expediente.etapaMediacion)
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const today = new Date()
    const now = today.toLocaleString()
    //SOLICITUD FECHA
    const [solicitudFecha, setSolicitudFecha] = useState({ solicitud: expediente.solicitudInformeDominio });
    //renderizar color completo
    let completoSolicitudExp = 0
    if (expediente.solicitudInformeDominio !== 0) { completoSolicitudExp += 1 }
    const actualizarSolicitudFecha = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/solicitudFecha`,
            {
                id: expediente.id,
                solicitud: solicitudFecha.solicitud,
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [solicitudFecha, expediente,])
    const completoSolicitudFecha = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoSolicitudFecha`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa SOLICITUD DE FECHA',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [solicitudFecha, expediente,])
    const activarSolicitudFecha = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/activarSolicitudFecha`,
            {
                id: expediente.id,
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [solicitudFecha, expediente,])

    //FECHA MEDIACION
    const [fechaMediacion, setFechaMediacion] = useState({ fecha: expediente.fechaMediacion, hora: expediente.horaMediacion, link: expediente.linkAudencias })
    //renderizar color completo
    let completoFechaExp = 0
    if (expediente.fechaMediacion !== '') { completoFechaExp += 1 }
    if (expediente.horaMediacion !== '') { completoFechaExp += 1 }
    if (expediente.linkAudencias !== '') { completoFechaExp += 1 }
    const actualizarFechaMediacion = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/fechaAsignada`,
            {
                id: expediente.id,
                fecha: new Date(fechaMediacion.fecha).toLocaleDateString('fr-FR'),
                hora: fechaMediacion.hora,
                link: fechaMediacion.link
            }
        ).then(async (response) => {
            if (response) {
                if (fechaMediacion.fecha !== '' && fechaMediacion.hora !== '') {
                    await axios.post(`https://api.tuveunchoque.com.ar/api/mail/mailMediacionFechaAsignada`, {
                        exp: expediente.nro,
                        fecha: fechaMediacion.fecha,
                        hora: fechaMediacion.hora
                    }
                    )
                    window.location.reload()
                } else {
                    window.location.reload()
                }
            }
        })
    }, [fechaMediacion, expediente,])
    const completoFechaMediacion = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoFechaAsignada`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa FECHA ASIGNADA',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [fechaMediacion, expediente,])
    const completo2FechaMediacion = useCallback(async (e) => {
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completo2FechaAsignada`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa FECHA ASIGNADA',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [fechaMediacion, expediente,])

    //NEGOCIACION
    const [negociacion, setNegociacion] = useState({ mail: expediente.mailAbogadoMediacion, ra: expediente.raMediacion })
    //renderizar color completo
    let completoNegociacionExp = 0
    if (expediente.mailAbogadoMediacion !== '') { completoNegociacionExp += 1 }
    if (expediente.raMediacion !== '') { completoNegociacionExp += 1 }
    const actualizarNegociacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/negociacion`,
            {
                id: expediente.id,
                mail: negociacion.mail,
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [negociacion, expediente,])
    const completoNegociacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoNegociacion`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa NEGOCIACION',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [negociacion, expediente,])
    const activarNegociacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/activarNegociacion`,
            {
                id: expediente.id,
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [negociacion, expediente,])

    //REVISIONES LEGALES
    const [revisionesLegales, setRevisionesLegales] = useState({ nombre: expediente.nombreDatoTestigoMediacion, dni: expediente.dniDatoTestigoMediacion, tel: expediente.telefonoDatoTestigoMediacion, tipo: expediente.tipoTestigoMediacion, demandado: expediente.juridisccionDemandadoMediacion, aseguradora: expediente.juridisccionAseguradoraMediacion, posibles: expediente.posiblesJuridisccionesMediacion, blsg: expediente.blsgRevisionesLegales, accidente: expediente.accidenteRevisionesLegales, estudioAsignado: expediente.estudioAsignadoRevisionesLegales, pagoGastosJudiciales: expediente.pagoGastosJudicialesRevisionesLegales })
    //renderizar color completo
    let completoRevisionesLegalesExp = 0
    if (expediente.nombreDatoTestigoMediacion !== '') { completoRevisionesLegalesExp += 1 }
    if (expediente.dniDatoTestigoMediacion !== '') { completoRevisionesLegalesExp += 1 }
    if (expediente.telefonoDatoTestigoMediacion !== '') { completoRevisionesLegalesExp += 1 }
    if (expediente.tipoTestigoMediacion !== '') { completoRevisionesLegalesExp += 1 }
    if (expediente.juridisccionDemandadoMediacion !== '') { completoRevisionesLegalesExp += 1 }
    if (expediente.juridisccionAseguradoraMediacion !== '') { completoRevisionesLegalesExp += 1 }
    if (expediente.posiblesJuridisccionesMediacion !== '') { completoRevisionesLegalesExp += 1 }
    const actualizarRevisionesLegales = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/revisionesLegales`,
            {
                id: expediente.id,
                estudioAsignado: revisionesLegales.estudioAsignado
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [revisionesLegales, expediente,])
    const completoRevisionesLegales = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoRevisionesLegales`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa REVISIONES LEGALES',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [revisionesLegales, expediente,])
    const atrasRevisionesLegales = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/atrasRevisionesLegales`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [revisionesLegales, expediente,])
    const cerradoRevisionesLegales = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/cerradoRevisionesLegales`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [revisionesLegales, expediente,])

    //ARMADO DERIVACION
    const [armadoDerivacion, setArmadoDerivacion] = useState({ acta: expediente.actaDeMediacion, entrevista: expediente.entrevistaMediacion, poder: expediente.poderFirmadoMediacion, documental: expediente.documentalCompletaMediacion })
    //renderizar color completo
    let completoArmadoDerivacionExp = 0
    if (expediente.actaDeMediacion !== 0) { completoArmadoDerivacionExp += 1 }
    if (expediente.entrevistaMediacion !== 0) { completoArmadoDerivacionExp += 1 }
    if (expediente.poderFirmadoMediacion !== 0) { completoArmadoDerivacionExp += 1 }
    if (expediente.documentalCompletaMediacion !== 0) { completoArmadoDerivacionExp += 1 }
    const actualizarArmadoDerivacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/armadoDerivacion`,
            {
                id: expediente.id,
                acta: armadoDerivacion.acta,
                entrevista: armadoDerivacion.entrevista,
                poderFirmado: armadoDerivacion.poder,
                documentalCompleta: armadoDerivacion.documental,
                nombre: revisionesLegales.nombre,
                dni: revisionesLegales.dni,
                tel: revisionesLegales.tel,
                tipoTestigo: revisionesLegales.tipo,
                demandado: revisionesLegales.demandado,
                aseguradora: revisionesLegales.aseguradora,
                posibles: revisionesLegales.posibles,
                blsg: revisionesLegales.blsg,
                accidente: revisionesLegales.accidente,
                gastosJudiciales: revisionesLegales.pagoGastosJudiciales,
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [armadoDerivacion, expediente, revisionesLegales])
    const completoArmadoDerivacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoArmadoDerivacion`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa ARMADO DERIVACION',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [armadoDerivacion, expediente,])

    //ESPERA CONFIRMACION
    const [esperaConfirmacion, setEsperaConfirmacion] = useState({ solicitud: expediente.solicitudDeDocumentalMediacion, text: expediente.solicitudDeDocumentalMediacionText })
    //renderizar color completo
    let completoEsperaConfirmacionExp = 0
    if (expediente.solicitudDeDocumentalMediacion != 0) { completoEsperaConfirmacionExp += 1 }
    const actualizarEsperaConfirmacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/esperaConfirmacion`,
            {
                id: expediente.id,
                solicitud: esperaConfirmacion.solicitud,
                text: esperaConfirmacion.text
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa ESPERA CONFIRMACION',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [esperaConfirmacion, expediente,])
    const completoEsperaConfirmacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoEsperaConfirmacion`,
            {
                id: expediente.id,
                fecha: new Date().toLocaleDateString('fr-FR'),
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa ESPERA CONFIRMACION',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [esperaConfirmacion, expediente,])
    // ACEPTACION MEDIACION
    const aceptarAceptacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/aceptarAceptacion`,
            {
                id: expediente.id,
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [esperaConfirmacion, expediente,])
    const completoAceptacion = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoAceptacion`,
            {
                id: expediente.id,
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa ACEPTACION DE MEDIACION',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.open(`https://sistema.tuveunchoque.com.ar/#/expediente/cobro/nuevo?nro=${expediente.nro}`, '_blank');
                window.location.reload()
            }
        })
    }, [esperaConfirmacion, expediente])
    // LEGALES
    const [legales, setLegales] = useState({ detallesExpedienteLegales: expediente.detallesExpedienteLegales })
    const actualizarLegales = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/actualizarLegales`,
            {
                id: expediente.id,
                detallesExpedienteLegales: legales.detallesExpedienteLegales
            }
        ).then(async (response) => {
            if (response) {
                window.location.reload()
            }
        })
    }, [legales, expediente,])
    const completoLegales = useCallback(async (e) => {
        e.preventDefault()
        const response = await axios.post(
            `https://api.tuveunchoque.com.ar/api/mediacion/completoLegales`,
            {
                id: expediente.id,
            }
        ).then(async (response) => {
            if (response) {
                await axios.post(
                    `https://api.tuveunchoque.com.ar/api/gerencia/detalles/nuevo`,
                    {
                        idExp: expediente.id,
                        cargo: cookies.user.cargo,
                        mensaje: 'Finaliza etapa LEGALES',
                        user: cookies.user.chat,
                        estado: expediente.estado,
                        clavado: false,
                        hoy: now,
                        ofrecimiento: false,
                        privado: false
                    }
                )
                window.location.reload()
            }
        })
    }, [esperaConfirmacion, expediente,])
    const btnAzul = { backgroundColor: '#6DB8F2', color: 'white' }
    const btnVerde = { backgroundColor: '#6DF2C7', color: 'white' }
    const btnNaranja = { backgroundColor: '#F2AB6D', color: 'white' }
    const btnNaranja2 = { backgroundColor: '#F2AB6D', color: 'white', width: '142px' }
    const btnRojo = { backgroundColor: '#F26D98', color: 'white' }
    return (
        <div>
            {etapa === 1 ?
                <div className='contenedorAbierto'>
                    <div onClick={() => setEtapa(1)} className='mediacionEnProceso'>
                        <p className='tituloAcordeonNaranja'>SOLICITUD DE FECHA</p>
                        <div className='center gap-3'>
                            <p className='tituloAcordeonGrisNaranja'>{`FECHA DE FINALIZACION: ${expediente.finalizacionSolicitudFecha}`}</p>
                            {completoSolicitudExp === 1 ? (
                                <div style={{ height: '30px', width: '30px', background: 'white', borderRadius: '50%', }} className='center'><i className="bi bi-check-circle-fill" style={{ fontSize: '37px', color: '#47BC82' }}></i></div>
                            ) : expediente.etapaMediacion === 1 ? (
                                <div style={{ height: '37px', width: '37px', background: '#FAEE9E', borderRadius: '50%' }}></div>
                            ) : (
                                <div style={{ height: '37px', width: '37px', background: 'transparent', borderRadius: '50%' }}></div>
                            )}
                        </div>
                    </div>
                    <div className='' style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '24px', paddingBottom: '24px' }}>
                        <div className="">
                            <label class="d-block labelMediacion" style={{ color: 'black' }}>Solicitud Informe Dominio</label>
                            <input
                                class="form-check-input"
                                type="checkbox"
                                checked={solicitudFecha.solicitud}
                                onChange={(e) => setSolicitudFecha((prevState) => ({
                                    ...prevState,
                                    solicitud: e.target.checked,
                                }))}
                            />
                        </div>
                        <Link to={`https://estudio-pyp.com.ar/formulario-mediacion-?EXP=${expediente.nro}`} target="_blank" rel="noopener noreferrer">
                            <button className='btn btn-primary' style={{ marginTop: '10px' }}>Generador de Texto Automatico</button>
                        </Link>
                        <div className="d-flex gap-3" style={{ marginTop: '10px' }}>
                            <button className='btnActAzul' onClick={(e) => actualizarSolicitudFecha()}>Actualizar</button>
                            <button className='btnActNaranja' onClick={(e) => activarSolicitudFecha()}>Activar</button>
                            <button className='btnActVerde' onClick={(e) => completoSolicitudFecha()}>Completo</button>
                        </div>
                    </div>
                </div>
                :
                <div onClick={() => setEtapa(1)} className='mediacionSinProceso'>
                    <p className='tituloAcordeonTransparente'>SOLICITUD DE FECHA</p>
                    <div className="center gap-3">
                        <p className='tituloAcordeonGrisBlanco'>{`FECHA DE FINALIZACION: ${expediente.finalizacionSolicitudFecha}`}</p>
                        {completoSolicitudExp === 1 ? (
                            <div style={{ height: '30px', width: '30px', background: 'white', borderRadius: '50%', }} className='center'><i className="bi bi-check-circle-fill" style={{ fontSize: '37px', color: '#47BC82' }}></i></div>
                        ) : expediente.etapaMediacion === 1 ? (
                            <div style={{ height: '37px', width: '37px', background: '#FAEE9E', borderRadius: '50%' }}></div>
                        ) : (
                            <div style={{ height: '37px', width: '37px', background: 'transparent', borderRadius: '50%' }}></div>
                        )}
                    </div>
                </div>}


            {etapa === 2 ?
                <div className="contenedorAbierto">
                    <div onClick={() => setEtapa(2)} className='mediacionEnProceso'>
                        <p className='tituloAcordeonNaranja'>FECHA ASIGNADA</p>
                        <div className='center gap-3'>
                            <p className='tituloAcordeonGrisNaranja'>{`FECHA DE FINALIZACION: ${expediente.finalizacionFechaAsignada}`}</p>
                            {completoFechaExp === 3 ? (
                                <div style={{ height: '30px', width: '30px', background: 'white', borderRadius: '50%', }} className='center'><i className="bi bi-check-circle-fill" style={{ fontSize: '37px', color: '#47BC82' }}></i></div>
                            ) : expediente.etapaMediacion === 2 || completoFechaExp > 0 ? (
                                <div style={{ height: '37px', width: '37px', background: '#FAEE9E', borderRadius: '50%' }}></div>
                            ) : (
                                <div style={{ height: '37px', width: '37px', background: 'transparent', borderRadius: '50%' }}></div>
                            )}
                        </div>
                    </div>
                    <div className='' style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '24px', paddingBottom: '24px' }}>
                        <div className='w-50'>
                            <label class="labelMediacion">Fecha de Mediacion: {expediente.fechaMediacion}</label>
                            <DatePicker locale='es' value={new Date(fechaMediacion.fecha).toLocaleDateString('fr-FR')} className="form-control form-control-sm"
                                onChange={(e) => setFechaMediacion((prevState) => ({
                                    ...prevState,
                                    fecha: e,
                                }))}
                            />
                        </div>
                        <div className="" style={{ marginTop: '10px' }}>
                            <label class="labelMediacion">Hora de Mediacion</label>
                            <input type="time" class="form-control w-25"
                                onChange={(e) => setFechaMediacion((prevState) => ({
                                    ...prevState,
                                    hora: e.target.value,
                                }))} />
                        </div>
                        <div className="w-50" style={{ marginTop: '10px' }}>
                            <label class="labelMediacion">Link Audiencias</label>
                            <input type="text" class="form-control" placeholder={fechaMediacion.link} value={fechaMediacion.link}
                                onChange={(e) => setFechaMediacion((prevState) => ({
                                    ...prevState,
                                    link: e.target.value,
                                }))} />
                        </div>
                        <div className="d-flex gap-3" style={{ marginTop: '10px' }}>
                            <button className='btnActAzul' onClick={(e) => actualizarFechaMediacion()}>Actualizar</button>
                            <button className='btnActNaranja' onClick={(e) => completo2FechaMediacion()}>Sin Negociacion</button>
                            <button className='btnActVerde' onClick={(e) => completoFechaMediacion()}>Negociacion</button>
                        </div>
                    </div>
                </div>
                :
                <div onClick={() => setEtapa(2)} className='mediacionSinProceso'>
                    <p className='tituloAcordeonTransparente'>FECHA ASIGNADA</p>
                    <div className='center gap-3'>
                        <p className='tituloAcordeonGrisBlanco'>{`FECHA DE FINALIZACION: ${expediente.finalizacionFechaAsignada}`}</p>
                        {completoFechaExp === 3 ? (
                            <div style={{ height: '30px', width: '30px', background: 'white', borderRadius: '50%', }} className='center'><i className="bi bi-check-circle-fill" style={{ fontSize: '37px', color: '#47BC82' }}></i></div>
                        ) : expediente.etapaMediacion === 2 | completoFechaExp > 0 ? (
                            <div style={{ height: '37px', width: '37px', background: '#FAEE9E', borderRadius: '50%' }}></div>
                        ) : (
                            <div style={{ height: '37px', width: '37px', background: 'transparent', borderRadius: '50%' }}></div>
                        )}
                    </div>
                </div>}


            {etapa === 3 ?
                <div className='contenedorAbierto'>
                    <div onClick={() => setEtapa(3)} className='mediacionEnProceso'>
                        <p className='tituloAcordeonNaranja'>NEGOCIACION</p>
                        <div className='center gap-3'>
                            <p className='tituloAcordeonGrisNaranja'>{`FECHA DE FINALIZACION: ${expediente.finalizacionNegociacion}`}</p>
                            {completoNegociacionExp === 2 ? (
                                <div style={{ height: '30px', width: '30px', background: 'white', borderRadius: '50%', }} className='center'><i className="bi bi-check-circle-fill" style={{ fontSize: '37px', color: '#47BC82' }}></i></div>
                            ) : expediente.etapaMediacion === 3 || completoNegociacionExp > 0 ? (
                                <div style={{ height: '37px', width: '37px', background: '#FAEE9E', borderRadius: '50%' }}></div>
                            ) : (
                                <div style={{ height: '37px', width: '37px', background: 'transparent', borderRadius: '50%' }}></div>
                            )}
                        </div>
                    </div>
                    <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '24px', paddingBottom: '24px' }}>
                        <div>
                            <label class="labelMediacion">Mail de Abogado</label>
                            <input type="text" class="form-control" placeholder={negociacion.mail} value={negociacion.mail}
                                onChange={(e) => setNegociacion((prevState) => ({
                                    ...prevState,
                                    mail: e.target.value,
                                }))} />
                        </div>
                        <div className="d-flex gap-3" style={{ marginTop: '10px' }}>
                            <button className='btnActAzul' onClick={(e) => actualizarNegociacion(e)}>Actualizar</button>
                            <button className='btnActNaranja' onClick={(e) => activarNegociacion(e)}>Activar</button>
                            <button className='btnActVerde' onClick={(e) => completoNegociacion(e)}>Completo</button>
                        </div>
                    </div>
                </div>
                :
                <div onClick={() => setEtapa(3)} className='mediacionSinProceso'>
                    <p className='tituloAcordeonTransparente'>NEGOCIACION</p>
                    <div className='center gap-3'>
                        <p className='tituloAcordeonGrisBlanco'>{`FECHA DE FINALIZACION: ${expediente.finalizacionNegociacion}`}</p>
                        {completoNegociacionExp === 2 ? (
                            <div style={{ height: '30px', width: '30px', background: 'white', borderRadius: '50%', }} className='center'><i className="bi bi-check-circle-fill" style={{ fontSize: '37px', color: '#47BC82' }}></i></div>
                        ) : expediente.etapaMediacion === 3 || completoNegociacionExp > 0 ? (
                            <div style={{ height: '37px', width: '37px', background: '#FAEE9E', borderRadius: '50%' }}></div>
                        ) : (
                            <div style={{ height: '37px', width: '37px', background: 'transparent', borderRadius: '50%' }}></div>
                        )}
                    </div>
                </div>}


            {etapa === 4 ?
                <div className='contenedorAbierto'>
                    <div onClick={() => setEtapa(4)} className='mediacionEnProceso'>
                        <p className='tituloAcordeonNaranja'>REVISIONES LEGALES</p>
                        <div className="center gap-3">
                            <p className='tituloAcordeonGrisNaranja'>{`FECHA DE FINALIZACION: ${expediente.finalizacionRevisionesLegales}`}</p>
                            {completoRevisionesLegalesExp === 7 ? (
                                <div style={{ height: '30px', width: '30px', background: 'white', borderRadius: '50%', }} className='center'><i className="bi bi-check-circle-fill" style={{ fontSize: '37px', color: '#47BC82' }}></i></div>
                            ) : expediente.etapaMediacion === 4 || completoRevisionesLegalesExp > 0 ? (
                                <div style={{ height: '37px', width: '37px', background: '#FAEE9E', borderRadius: '50%' }}></div>
                            ) : (
                                <div style={{ height: '37px', width: '37px', background: 'transparent', borderRadius: '50%' }}></div>
                            )}</div>
                    </div>
                    <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '24px', paddingBottom: '24px' }}>
                        <div className="">
                            <label class="mb-1 labelMediacion">Estudio Asignado</label>
                            <Select
                                menuPortalTarget={document.body}
                                options={empleadoAsignado.map(asignado => ({ value: asignado.nombre, label: asignado.nombre }))}
                                placeholder={revisionesLegales.estudioAsignado}
                                onChange={(newValue) => setRevisionesLegales((prevState) => ({
                                    ...prevState,
                                    estudioAsignado: newValue.value,
                                }))}
                            />
                        </div>
                        <div className="d-flex gap-3" style={{ marginTop: '10px' }}>
                            <button className='btnActAzul' onClick={(e) => actualizarRevisionesLegales(e)}>Actualizar</button>
                            <button className='btnActNaranja' onClick={(e) => atrasRevisionesLegales(e)}>Negociacion</button>
                            <button className='btnActVerde' onClick={(e) => completoRevisionesLegales(e)}>Completo</button>
                            <button className='btnActRojo' onClick={(e) => cerradoRevisionesLegales(e)}>Cerrado</button>
                        </div>
                    </div>
                </div>
                :
                <div onClick={() => setEtapa(4)} className='mediacionSinProceso'>
                    <p className='tituloAcordeonTransparente'>REVISIONES LEGALES</p>
                    <div className="center gap-3">
                        <p className='tituloAcordeonGrisBlanco'>{`FECHA DE FINALIZACION: ${expediente.finalizacionRevisionesLegales}`}</p>
                        {completoRevisionesLegalesExp === 7 ? (
                            <div style={{ height: '30px', width: '30px', background: 'white', borderRadius: '50%', }} className='center'><i className="bi bi-check-circle-fill" style={{ fontSize: '37px', color: '#47BC82' }}></i></div>
                        ) : expediente.etapaMediacion === 4 || completoRevisionesLegalesExp > 0 ? (
                            <div style={{ height: '37px', width: '37px', background: '#FAEE9E', borderRadius: '50%' }}></div>
                        ) : (
                            <div style={{ height: '37px', width: '37px', background: 'transparent', borderRadius: '50%' }}></div>
                        )}
                    </div>
                </div>}


            {etapa === 5 ?
                <div className='contenedorAbierto'>
                    <div onClick={() => setEtapa(5)} className='mediacionEnProceso'>
                        <p className='tituloAcordeonNaranja'>ARMADO DERIVACION</p>
                        <div className='center gap-3'>
                            <p className='tituloAcordeonGrisNaranja'>{`FECHA DE FINALIZACION: ${expediente.finalizacionArmadoDerivacion}`}</p>
                            {completoArmadoDerivacionExp === 4 ? (
                                <div style={{ height: '30px', width: '30px', background: 'white', borderRadius: '50%', }} className='center'><i className="bi bi-check-circle-fill" style={{ fontSize: '37px', color: '#47BC82' }}></i></div>
                            ) : expediente.etapaMediacion === 5 || completoArmadoDerivacionExp > 0 ? (
                                <div style={{ height: '37px', width: '37px', background: '#FAEE9E', borderRadius: '50%' }}></div>
                            ) : (
                                <div style={{ height: '37px', width: '37px', background: 'transparent', borderRadius: '50%' }}></div>
                            )}
                        </div>
                    </div>
                    <div className='d-flex gap-3' style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '24px', paddingBottom: '24px' }}>
                        <div className='w-100'>
                            <div>
                                <label class="labelMediacion">Nombre</label>
                                <input type="text" class="form-control" placeholder={expediente.nombreDatoTestigoMediacion}
                                    onChange={(e) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        nombre: e.target.value,
                                    }))} />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <label class="labelMediacion">D.N.I</label>
                                <input type="text" class="form-control" placeholder={expediente.dniDatoTestigoMediacion}
                                    onChange={(e) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        dni: e.target.value,
                                    }))} />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <label class="labelMediacion">Telefono</label>
                                <input type="text" class="form-control" placeholder={expediente.telefonoDatoTestigoMediacion}
                                    onChange={(e) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        tel: e.target.value,
                                    }))} />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <div>
                                    <label class="labelMediacion">Tipo de Testigo</label>
                                    <div className="d-flex">
                                        <div className='divCheckboxAmpliaciones form-check form-switch me-4'>
                                            <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={(e) => setRevisionesLegales((prevState) => ({ ...prevState, blsg: e.target.checked, }))} checked={revisionesLegales.blsg} />
                                            <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">BLSG</label>
                                        </div>
                                        <div className='divCheckboxAmpliaciones form-check form-switch ms-4'>
                                            <input type="checkbox" className='checkboxAmpliaciones form-check-input' for="flexSwitchCheckDefault" role="switch" onChange={(e) => setRevisionesLegales((prevState) => ({ ...prevState, accidente: e.target.checked, }))} checked={revisionesLegales.accidente} />
                                            <label htmlFor="" className='labelCheckboxAmpliaciones' id="flexSwitchCheckDefault">ACCIDENTE</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex gap-3' style={{ marginTop: '10px' }}>
                                <div className='me-1'><button className='btnActAzul' onClick={(e) => actualizarArmadoDerivacion(e)}>Actualizar</button></div>
                                <div className='me-1'><button className='btnActVerde' onClick={(e) => completoArmadoDerivacion(e)}>Completo</button></div>
                            </div>
                        </div>
                        <div className='w-100'>
                            <div>
                                <label class="labelMediacion">Juridisccion Demandado</label>
                                <input type="text" class="form-control" placeholder={expediente.juridisccionDemandadoMediacion}
                                    onChange={(e) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        demandado: e.target.value,
                                    }))} />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <label class="labelMediacion">Juridisccion Aseguradora</label>
                                <input type="text" class="form-control" placeholder={expediente.juridisccionAseguradoraMediacion}
                                    onChange={(e) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        aseguradora: e.target.value,
                                    }))} />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <label class="labelMediacion">Posibles Juridiscciones A Iniciar (CABA - PROVINCIA)</label>
                                <input type="text" class="form-control" placeholder={expediente.posiblesJuridisccionesMediacion}
                                    onChange={(e) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        posibles: e.target.value,
                                    }))} />
                            </div>
                            <div style={{ marginTop: '10px' }}>
                                <label class="mb-1 labelMediacion">PAGO DE GASTOS JUDICIALES</label>
                                <Select
                                    menuPortalTarget={document.body}
                                    options={pagoGastosJudiciales.map(asignado => ({ value: asignado, label: asignado }))}
                                    placeholder={revisionesLegales.pagoGastosJudiciales}
                                    onChange={(newValue) => setRevisionesLegales((prevState) => ({
                                        ...prevState,
                                        pagoGastosJudiciales: newValue.value,
                                    }))}
                                />
                            </div>
                            <div className="d-flex gap-3" style={{ marginTop: '10px' }}>
                                <div className="">
                                    <label class="d-block labelMediacion">Acta Mediacion</label>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={armadoDerivacion.acta}
                                        onChange={(e) => setArmadoDerivacion((prevState) => ({
                                            ...prevState,
                                            acta: e.target.checked,
                                        }))}
                                    />
                                </div>
                                <div className="">
                                    <label class="d-block labelMediacion">Entrevista</label>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={armadoDerivacion.entrevista}
                                        onChange={(e) => setArmadoDerivacion((prevState) => ({
                                            ...prevState,
                                            entrevista: e.target.checked,
                                        }))}
                                    />
                                </div>
                                <div className="">
                                    <label class="d-block labelMediacion">Poder Firmado</label>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={armadoDerivacion.poder}
                                        onChange={(e) => setArmadoDerivacion((prevState) => ({
                                            ...prevState,
                                            poder: e.target.checked,
                                        }))}
                                    />
                                </div>
                                <div className="">
                                    <label class="d-block labelMediacion">Doc. Completa</label>
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        checked={armadoDerivacion.documental}
                                        onChange={(e) => setArmadoDerivacion((prevState) => ({
                                            ...prevState,
                                            documental: e.target.checked,
                                        }))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div onClick={() => setEtapa(5)} className='mediacionSinProceso'>
                    <p className='tituloAcordeonTransparente'>ARMADO DERIVACION</p>
                    <div className='center gap-3'>
                        <p className='tituloAcordeonGrisBlanco'>{`FECHA DE FINALIZACION: ${expediente.finalizacionArmadoDerivacion}`}</p>
                        {completoArmadoDerivacionExp === 4 ? (
                            <div style={{ height: '30px', width: '30px', background: 'white', borderRadius: '50%', }} className='center'><i className="bi bi-check-circle-fill" style={{ fontSize: '37px', color: '#47BC82' }}></i></div>
                        ) : expediente.etapaMediacion === 5 || completoArmadoDerivacionExp > 0 ? (
                            <div style={{ height: '37px', width: '37px', background: '#FAEE9E', borderRadius: '50%' }}></div>
                        ) : (
                            <div style={{ height: '37px', width: '37px', background: 'transparent', borderRadius: '50%' }}></div>
                        )}
                    </div>
                </div>}


            {etapa === 6 ?
                <div className='contenedorAbierto'>
                    <div onClick={() => setEtapa(6)} className='mediacionEnProceso'>
                        <p className='tituloAcordeonNaranja'>ESPERA CONFIRMACION</p>
                        <div className="center gap-3">
                            <p className='tituloAcordeonGrisNaranja'>{`FECHA DE FINALIZACION: ${expediente.finalizacionEsperaConfirmacion}`}</p>
                            {completoEsperaConfirmacionExp === 1 ? (
                                <div style={{ height: '30px', width: '30px', background: 'white', borderRadius: '50%', }} className='center'><i className="bi bi-check-circle-fill" style={{ fontSize: '37px', color: '#47BC82' }}></i></div>
                            ) : expediente.etapaMediacion === 6 ? (
                                <div style={{ height: '37px', width: '37px', background: '#FAEE9E', borderRadius: '50%' }}></div>
                            ) : (
                                <div style={{ height: '37px', width: '37px', background: 'transparent', borderRadius: '50%' }}></div>
                            )}
                        </div>
                    </div>
                    <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '24px', paddingBottom: '24px' }}>
                        <div className="w-25">
                            <label class="labelMediacion">Solicitud de Documental</label>
                            <Select
                                menuPortalTarget={document.body}
                                options={solicitudDocumentalInput.map(les => ({ value: les, label: les }))}
                                className=""
                                onChange={(newValue) => setEsperaConfirmacion((prevState) => ({
                                    ...prevState,
                                    solicitud: newValue.value,
                                }))}
                                placeholder={expediente.solicitudDeDocumentalMediacion}
                            />
                        </div>
                        <div className="" style={{ marginTop: '10px' }}>
                            {esperaConfirmacion.solicitud === 'SI' ? <div><textarea
                                className="form-control"
                                value={esperaConfirmacion.text}
                                onChange={(e) =>
                                    setEsperaConfirmacion((prevState) => ({
                                        ...prevState,
                                        text: e.target.value,
                                    }))}
                            /></div> : <div></div>}
                        </div>
                        <div className="d-flex gap-3" style={{ marginTop: '10px' }}>
                            <button className='btnActAzul' onClick={(e) => actualizarEsperaConfirmacion(e)}>Actualizar</button>
                            <button className='btnActVerde' onClick={(e) => completoEsperaConfirmacion(e)}>Completo</button>
                        </div>
                    </div>
                </div>
                :
                <div onClick={() => setEtapa(6)} className='mediacionSinProceso'>
                    <p className='tituloAcordeonTransparente'>ESPERA CONFIRMACION</p>
                    <div className="center gap-3">
                        <p className='tituloAcordeonGrisBlanco'>{`FECHA DE FINALIZACION: ${expediente.finalizacionEsperaConfirmacion}`}</p>
                        {completoEsperaConfirmacionExp === 1 ? (
                            <div style={{ height: '30px', width: '30px', background: 'white', borderRadius: '50%', }} className='center'><i className="bi bi-check-circle-fill" style={{ fontSize: '37px', color: '#47BC82' }}></i></div>
                        ) : expediente.etapaMediacion === 6 ? (
                            <div style={{ height: '37px', width: '37px', background: '#FAEE9E', borderRadius: '50%' }}></div>
                        ) : (
                            <div style={{ height: '37px', width: '37px', background: 'transparent', borderRadius: '50%' }}></div>
                        )}
                    </div>
                </div>}


            {etapa === 8 ?
                <div className='contenedorAbierto'>
                    <div onClick={() => setEtapa(8)} className='mediacionEnProceso'>
                        <p className='tituloAcordeonNaranja'>LEGALES</p>
                    </div>
                    <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '24px', paddingBottom: '24px' }}>
                        <div className="">
                            <label class="mb-1 labelMediacion">Estudio Asignado</label>
                            <input type="text" class="form-control" value={expediente.estudioAsignadoRevisionesLegales} />
                        </div>
                        <div className="" style={{ marginTop: '10px' }}>
                            <label class="mb-1 labelMediacion">Detalles Expediente</label>
                            <textarea class="form-control" value={legales.detallesExpedienteLegales} onChange={(e) =>
                                setLegales((prevState) => ({
                                    ...prevState,
                                    detallesExpedienteLegales: e.target.value,
                                }))} />
                        </div>
                        <div className="d-flex gap-3" style={{ marginTop: '10px' }}>
                            <button className='btnActAzul' onClick={(e) => actualizarLegales(e)}>Actualizar</button>
                            <button className='btnActVerde' onClick={(e) => completoLegales(e)}>Completo</button>
                        </div>
                    </div>
                </div>
                :
                <div onClick={() => setEtapa(8)} className='mediacionSinProceso'>
                    <p className='tituloAcordeonTransparente'>LEGALES</p>
                </div>}


            {etapa === 7 ?
                <div className='contenedorAbierto'>
                    <div onClick={() => setEtapa(7)} className='mediacionEnProceso'>
                        <p className='tituloAcordeonNaranja'>ACEPTACION MEDIACION</p>
                    </div>
                    <div style={{ paddingLeft: '24px', paddingRight: '24px', paddingTop: '24px', paddingBottom: '24px' }}>
                        <div className="d-flex gap-3">
                            <button className='btnActAzul' onClick={(e) => aceptarAceptacion(e)}>ACEPTAR</button>
                            <button className='btnActVerde' onClick={(e) => completoAceptacion(e)}>Completo</button>
                        </div>
                    </div>
                </div>
                :
                <div onClick={() => setEtapa(7)} className='mediacionSinProceso'>
                    <p className='tituloAcordeonTransparente'>ACEPTACION MEDIACION</p>
                </div>}
        </div>
    )
}
