import { useState } from "react";
import * as XLSX from "xlsx";

export default function Excel({ data }) {
    const [loading, setLoading] = useState(false);
    const handleDownload = () => {
        setLoading(true);
        const fieldsOrder = [
            "id", 
            "nro", 
            "fechaStro", 
            "fechaExp",
            "fechaVencimiento", 
            "fechaDeAsignado", 
            "fechaCambioEstado",
            "ultimaMod", 
            "nombre", 
            "dominioCliente", 
            "productorAsociado", 
            "compania", 
            "estado",
            "asignadoA"
        ];
        const numericFields = ["monto", "honorariosCobro", "honorariosFacturado", "capital", "nivel", 'hnriosCliente', 'ticket', 'callbell', 'callbellRequerido', ];
        const sortedData = data.map(item => {
            const sortedItem = {};
            // Añade los campos en el orden especificado
            fieldsOrder.forEach(field => {
                if (item[field] !== undefined) {
                    sortedItem[field] = item[field];
                }
            });
            // Procesa los campos numéricos
            numericFields.forEach(field => {
                if (item.hasOwnProperty(field)) {
                    const numValue = parseFloat(item[field]);
                    sortedItem[field] = isNaN(numValue) ? "" : numValue;
                }
            });
            // Añade los campos restantes que no están en fieldsOrder ni en numericFields
            Object.keys(item).forEach(field => {
                if (!fieldsOrder.includes(field) && !numericFields.includes(field)) {
                    sortedItem[field] = item[field];
                }
            });
    
            return sortedItem;
        });
        const libro = XLSX.utils.book_new();
        const hoja = XLSX.utils.json_to_sheet(sortedData);
        XLSX.utils.book_append_sheet(libro, hoja, "Expedientes");
        setTimeout(() => {
            XLSX.writeFile(libro, "data.xlsx");
            setLoading(false);
        }, 1000);
    };
    
    return (
        <>
            <button className="btn btn-secondary" onClick={handleDownload} disabled={loading}>
                {loading ? "Cargando..." : "Exportar a Excel"}
            </button>
        </>
    );
}


