import axios from "axios";
import { useState, useEffect } from "react";
import Expediente from "../components/Expedientes/Expediente";
import Cobros from "../components/Expedientes/Cobros";
import "./index.css";
import "./tablaGerencia.css";
import { useCookies } from 'react-cookie';
import moment from "moment/moment";
import { MultiSelect } from "react-multi-select-component";
import ReactPaginate from 'react-paginate';
import './paginate.css'
import Excel from "./Excel";
import iconoCobro from "../images/iconoCobro.png";
import iconoExpediente from "../images/iconoExpediente.png";
import iconoFiltrar from "../images/iconoFiltrar.png";
import iconoGlobal from "../images/iconoGlobal.png";
import { useNavigate } from "react-router-dom";
import ExcelResumen from "./ExcelResumen";
import { useMediaQuery } from 'react-responsive'
import banner from "../images/bannerLoginCliente.jpeg";
import ExpedienteTablaMovil from "../components/TablaMovil/ExpedienteTablaMovil";
export default function TablaGerencia() {
    const navigate = useNavigate()
    const a = localStorage.getItem('user');
    if (a === null) {
        navigate('/login')
    }
    const [cookies, setCookie, removeCookie] = useCookies(['user']);
    const [expedientes, setExpedientes] = useState([]);
    const [productores, setProductores] = useState([]);
    const [companias, setCompanias] = useState([]);
    const [preCarga, setPreCarga] = useState(false)
    useEffect(() => {
        const fetchExpedientes = async () => {
            try {
                if (cookies.user.nombre === 'FRANCO') {
                    const res1 = await axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/tablaGerenciaFranco");
                    if (res1.data && res1.data.length > 0) {
                        setExpedientes(res1.data);
                        setPreCarga(true);
                        const res2 = await axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/tablaGerencia");
                        setExpedientes(res2.data.reverse());
                    }
                } else {
                    const res = await axios.get("https://api.tuveunchoque.com.ar/api/gerencia/expedientes/tablaGerencia");
                    setExpedientes(res.data.reverse());
                    setPreCarga(true);
                }
                const [resProductores, resCompanias] = await Promise.all([
                    axios.get("https://api.tuveunchoque.com.ar/api/nuevosUsuarios/pasParaFiltros"),
                    axios.get("https://api.tuveunchoque.com.ar/api/gerencia/companias")
                ]);
                setProductores(resProductores.data);
                setCompanias(resCompanias.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchExpedientes();
    }, []);
    const estadoInput = [
        { label: "RECEPCIONADO", value: "RECEPCIONADO" },
        { label: "SIN DENUNCIA", value: "SIN DENUNCIA" },
        { label: "ARMADO PRELIMINAR", value: "ARMADO PRELIMINAR" },
        { label: "RECLAMO PRESENTADO", value: "RECLAMO PRESENTADO" },
        { label: "ACEPTACION", value: "ACEPTACION" },
        { label: "EN PROCESO DE PAGO", value: "EN PROCESO DE PAGO" },
        { label: "COBRADO", value: "COBRADO" },
        { label: "MEDIACION", value: "MEDIACION" },
        { label: "CERRADO", value: "CERRADO" },
    ];
    const nivelInput = [{ label: "0", value: "0" }, { label: "1", value: "1" }, { label: "2", value: "2" }, { label: "3", value: "3" }];
    const fechaInput = [{ label: "HOY", value: "HOY" }, { label: "MAÑANA", value: "MAÑANA" }, { label: "VENCIDOS", value: "VENCIDOS" }];
    const asignadoInput = [
        { label: "AGOSTINA", value: "AGOSTINA" },
        { label: "CATALINA", value: "CATALINA" },
        { label: "DANIELA", value: "DANIELA" },
        { label: "FRANCO", value: "FRANCO" },
        { label: "JUAN", value: "JUAN" },
        { label: "SOFIA", value: "SOFIA" },
        { label: "MICAELA", value: "MICAELA" },
        { label: "FEDERICO", value: "FEDERICO" },
        { label: 'SANDRA', value: 'SANDRA' },
        { label: "FACUNDO", value: 'FACUNDO' },
        { label: "LUCAS", value: 'LUCAS' },
        { label: "GONZALO", value: 'GONZALO' },
        { label: "", value: null }
    ];
    const compInput = companias.map((c) => {
        return { value: c.compania, label: c.compania }
    })
    const proInput = productores.map((p) => {
        return { value: p.nombre, label: p.nombre }
    })
    const pagoInput = ['CHEQUE', 'CLIENTE', 'TRANSFERENCIA']
    const estadoCobroInput = ['PENDIENTE', 'COBRADO']
    const facturadoInput = ['JUAN', 'FRANCO', 'MAURO', 'CLIENTE', 'SANDRA', 'FERLAUTO']
    let updatedList = expedientes;
    const [infoTabla, setInfoTabla, removeInfoTabla] = useCookies(['tablaGerencia'], ['user']);
    const [info, setInfo] = useState({
        info: infoTabla.tablaGerencia.info,
        pas: infoTabla.tablaGerencia.pas,
        comp: infoTabla.tablaGerencia.comp,
        estado: infoTabla.tablaGerencia.estado,
        nivel: infoTabla.tablaGerencia.nivel,
        fecha: infoTabla.tablaGerencia.fecha,
        asignado: infoTabla.tablaGerencia.asignado,
        vista: true,
        mesCobradoFrom: infoTabla.tablaGerencia.mesCobradoFrom,
        mesCobradoTo: infoTabla.tablaGerencia.mesCobradoTo,
        mesEstimadoFrom: infoTabla.tablaGerencia.mesEstimadoFrom,
        mesEstimadoTo: infoTabla.tablaGerencia.mesEstimadoTo,
        expFrom: infoTabla.tablaGerencia.expFrom,
        expTo: infoTabla.tablaGerencia.expTo,
        modoDePago: infoTabla.tablaGerencia.modoDePago,
        estadoCobro: infoTabla.tablaGerencia.estadoCobro,
        nroFactura: infoTabla.tablaGerencia.nroFactura,
        facturadoPor: infoTabla.tablaGerencia.facturadoPor,
        honorariosFrom: infoTabla.tablaGerencia.honorariosFrom,
        honorariosTo: infoTabla.tablaGerencia.honorariosTo,
        nroFrom: infoTabla.tablaGerencia.nroFrom,
        nroTo: infoTabla.tablaGerencia.nroTo
    });
    function convertToDate(dateString) {
        const parts = dateString.split("/");
        // Month is 0-based, so we subtract 1
        return new Date(parts[2], parts[1] - 1, parts[0]);
    }
    const conFiltro = () => {
        if (infoTabla.tablaGerencia.info) {
            updatedList = updatedList.filter((expediente) =>
                JSON.stringify(expediente.nro).includes(infoTabla.tablaGerencia.info) ||
                JSON.stringify(expediente.polizaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').includes(infoTabla.tablaGerencia.info) ||
                JSON.stringify(expediente.nroReclamoCompaniaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').includes(infoTabla.tablaGerencia.info) ||
                JSON.stringify(expediente.polizaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substring(-4).includes(infoTabla.tablaGerencia.info) ||
                JSON.stringify(expediente.nroReclamoCompaniaRequerido).replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '').substring(-4).includes(infoTabla.tablaGerencia.info) ||
                JSON.stringify(expediente.dominioCliente).toLowerCase().includes(JSON.stringify(infoTabla.tablaGerencia.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.productorAsociado).toLowerCase().includes(JSON.stringify(infoTabla.tablaGerencia.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nombre).toLowerCase().includes(JSON.stringify(infoTabla.tablaGerencia.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.compania).toLowerCase().includes(JSON.stringify(infoTabla.tablaGerencia.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.patenteRequerido).toLowerCase().includes(JSON.stringify(infoTabla.tablaGerencia.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nroSeguimientoCompaniaRequerido).toLowerCase().includes(JSON.stringify(infoTabla.tablaGerencia.info).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
            );
        }
        if (infoTabla.tablaGerencia.pas.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tablaGerencia.pas.some((ele) => {
                    return ele.value === expediente.productorAsociado
                })
            })
        }
        if (infoTabla.tablaGerencia.comp.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tablaGerencia.comp.some((ele) => {
                    return ele.value === expediente.compania
                })
            })
        }
        if (infoTabla.tablaGerencia.estado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tablaGerencia.estado.some((ele) => {
                    return ele.value === expediente.estado
                })
            })
        }
        if (infoTabla.tablaGerencia.asignado.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tablaGerencia.asignado.some((ele) => {
                    return ele.value === expediente.asignadoA
                })
            })
        }
        if (infoTabla.tablaGerencia.nivel.length > 0) {
            updatedList = updatedList.filter((expediente) => {
                return infoTabla.tablaGerencia.nivel.some((ele) => {
                    return ele.value === expediente.nivel
                })
            })
        }
        if (infoTabla.tablaGerencia.nroFrom !== null) {
            updatedList = updatedList.filter((expediente) => {
                return expediente.nro >= infoTabla.tablaGerencia.nroFrom
            })
        }
        if (infoTabla.tablaGerencia.nroTo !== null) {
            updatedList = updatedList.filter((expediente) => {
                return expediente.nro <= infoTabla.tablaGerencia.nroTo
            })
        }
        if (infoTabla.tablaGerencia.fecha.some((ele) => { return ele.value === "HOY"; })) {
            updatedList = updatedList.filter((e) => new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toDateString() === new Date().toDateString());
        }
        if (infoTabla.tablaGerencia.fecha.some((ele) => { return ele.value === "MAÑANA"; })) {
            updatedList = updatedList.filter((e) => new Date(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()).toLocaleDateString() === new Date(+new Date().setHours(0, 0, 0, 0) + 86400000).toLocaleDateString());
        }
        if (infoTabla.tablaGerencia.fecha.some((ele) => { return ele.value === "VENCIDOS" })) {
            updatedList = updatedList.filter((e) => e.estado !== 'COBRADO' && e.estado !== 'CERRADO' && Date.parse(moment(e.fechaVencimiento, "DD/MM/YYYY").toDate()) < new Date().setHours(0, 0, 0, 0));
        }
        if (infoTabla.tablaGerencia.estadoCobro) { updatedList = updatedList.filter((expediente) => expediente.estadoCobro === infoTabla.tablaGerencia.estadoCobro) }
        if (infoTabla.tablaGerencia.nroFactura) { updatedList = updatedList.filter((expediente) => JSON.stringify(expediente.nroFactura).includes(infoTabla.tablaGerencia.nroFactura)) }
        if (infoTabla.tablaGerencia.facturadoPor) { updatedList = updatedList.filter((expediente) => expediente.facturadoPor === infoTabla.tablaGerencia.facturadoPor) }
        if (infoTabla.tablaGerencia.modoDePago) { updatedList = updatedList.filter((expediente) => expediente.modoDePago === infoTabla.tablaGerencia.modoDePago) }
        if (infoTabla.tablaGerencia.honorariosFrom) { updatedList = updatedList.filter((expediente) => Number(expediente.honorariosFacturado) >= infoTabla.tablaGerencia.honorariosFrom) }
        if (infoTabla.tablaGerencia.honorariosTo) { updatedList = updatedList.filter((expediente) => Number(expediente.honorariosFacturado) <= infoTabla.tablaGerencia.honorariosTo) }
        if (infoTabla.tablaGerencia.mesCobradoFrom) { updatedList = updatedList.filter((expediente) => (Date.parse(expediente.mesCobrado)) > (new Date(infoTabla.tablaGerencia.mesCobradoFrom).setHours(0, 0, 0, 0))) }
        if (infoTabla.tablaGerencia.mesCobradoTo) { updatedList = updatedList.filter((expediente) => (Date.parse(expediente.mesCobrado)) < (new Date(infoTabla.tablaGerencia.mesCobradoTo).setHours(0, 0, 0, 0))) }
        if (infoTabla.tablaGerencia.mesEstimadoFrom) { updatedList = updatedList.filter((expediente) => (Date.parse(expediente.mesEstimado)) > (new Date(infoTabla.tablaGerencia.mesEstimadoFrom).setHours(0, 0, 0, 0))) }
        if (infoTabla.tablaGerencia.mesEstimadoTo) { updatedList = updatedList.filter((expediente) => (Date.parse(expediente.mesEstimado)) < (new Date(infoTabla.tablaGerencia.mesEstimadoTo).setHours(0, 0, 0, 0))) }
        if (infoTabla.tablaGerencia.expFrom) { updatedList = updatedList.filter((expediente) => expediente.fechaStro.includes('/') && convertToDate(expediente.fechaStro) >= new Date(infoTabla.tablaGerencia.expFrom + 'T00:00:00')) }
        if (infoTabla.tablaGerencia.expTo) { updatedList = updatedList.filter((expediente) => expediente.fechaExp.includes('/') && convertToDate(expediente.fechaStro) <= new Date(infoTabla.tablaGerencia.expTo + 'T00:00:00')) }
        if (!info.vista) { updatedList = updatedList.filter((expediente) => expediente.capital !== null && (expediente.estado === 'COBRADO' || expediente.estado === 'EN PROCESO DE PAGO')) }
    };
    conFiltro();
    useEffect(() => {
        setCookie('tablaGerencia', JSON.stringify(info), { path: '/' });
    }, [info]);
    const vaciar = async () => {
        setInfo((prevState) => ({ ...prevState, info: "", pas: [], comp: [], estado: [], nivel: [], fecha: [], asignado: [], mesCobradoFrom: '', mesCobradoTo: '', mesEstimadoFrom: '', mesEstimadoTo: '', expFrom: '', expTo: '', modoDePago: '', estadoCobro: '', nroFactura: '', facturadoPor: '', honorariosFrom: '', honorariosTo: '', nroFrom: null, nroTo: null }));
    }
    const btn = { border: 'none', background: 'none', fontSize: '12px', color: '#0089FF', fontWeight: '100' }
    const parseDate = (dateStr) => {
        if (!dateStr) return null; // Si está vacío, retorna null
        const date = moment(dateStr, "DD/MM/YYYY", true);
        return date.isValid() ? date.toDate() : new Date(dateStr);
    };
    const [sortA, setSortA] = useState(1)
    const sortFunctions = {
        1: (a, b) => b.nro - a.nro,
        2: (a, b) => a.nro - b.nro,
        3: (a, b) => { if (a.dominioCliente && b.dominioCliente) return JSON.stringify(a.dominioCliente).localeCompare(JSON.stringify(b.dominioCliente)) },
        4: (a, b) => { if (a.dominioCliente && b.dominioCliente) return JSON.stringify(b.dominioCliente).localeCompare(JSON.stringify(a.dominioCliente)) },
        5: (a, b) => (JSON.stringify(a.productorAsociado).toLowerCase() || '').localeCompare((JSON.stringify(b.productorAsociado).toLowerCase() || '')),
        6: (a, b) => (JSON.stringify(b.productorAsociado).toLowerCase() || '').localeCompare((JSON.stringify(a.productorAsociado).toLowerCase() || '')),
        7: (a, b) => {
            const dateA = parseDate(a.fechaCambioEstado);
            const dateB = parseDate(b.fechaCambioEstado);
            if (!dateA && !dateB) return 0;       // Ambos vacíos, se mantienen igual
            if (!dateA) return 1;                // a está vacío, va después de b
            if (!dateB) return -1;               // b está vacío, va después de a
            return dateB - dateA;                // Orden descendente
        },
        8: (a, b) => {
            const dateA = parseDate(a.fechaCambioEstado);
            const dateB = parseDate(b.fechaCambioEstado);
            if (!dateA && !dateB) return 0;       // Ambos vacíos, se mantienen igual
            if (!dateA) return 1;                // a está vacío, va después de b
            if (!dateB) return -1;               // b está vacío, va después de a
            return dateA - dateB;                // Orden ascendente
        },
        9: (a, b) => {
            const nameA = (a.compania || '').toString().toLowerCase();
            const nameB = (b.compania || '').toString().toLowerCase();
            if (!nameA && !nameB) return 0;  // Ambos vacíos, se mantienen igual
            if (!nameA) return 1;           // a está vacío, va después de b
            if (!nameB) return -1;          // b está vacío, va después de a
            return nameA.localeCompare(nameB);
        },
        10: (a, b) => {
            const nameA = (b.compania || '').toString().toLowerCase();
            const nameB = (a.compania || '').toString().toLowerCase();
            if (!nameA && !nameB) return 0;  // Ambos vacíos, se mantienen igual
            if (!nameA) return 1;           // a está vacío, va después de b
            if (!nameB) return -1;          // b está vacío, va después de a
            return nameA.localeCompare(nameB);
        },
        11: (a, b) => {
            const nameA = (a.nombre || '').toString().toLowerCase();
            const nameB = (b.nombre || '').toString().toLowerCase();
            if (!nameA && !nameB) return 0;  // Ambos vacíos, se mantienen igual
            if (!nameA) return 1;           // a está vacío, va después de b
            if (!nameB) return -1;          // b está vacío, va después de a
            return nameA.localeCompare(nameB);
        },
        12: (a, b) => {
            const nameA = (b.nombre || '').toString().toLowerCase();
            const nameB = (a.nombre || '').toString().toLowerCase();

            if (!nameA && !nameB) return 0;  // Ambos vacíos, se mantienen igual
            if (!nameA) return 1;           // a está vacío, va después de b
            if (!nameB) return -1;          // b está vacío, va después de a

            return nameA.localeCompare(nameB);
        },
        13: (a, b) => {
            const dateA = parseDate(a.fechaVencimiento);
            const dateB = parseDate(b.fechaVencimiento);
            if (!dateA && !dateB) return 0;   // Ambos vacíos, se mantienen igual
            if (!dateA) return 1;            // a está vacío, va después de b
            if (!dateB) return -1;           // b está vacío, va después de a
            return dateB - dateA;
        },
        14: (a, b) => {
            const dateA = parseDate(a.fechaVencimiento);
            const dateB = parseDate(b.fechaVencimiento);
            if (!dateA && !dateB) return 0;   // Ambos vacíos, se mantienen igual
            if (!dateA) return 1;            // a está vacío, va después de b
            if (!dateB) return -1;           // b está vacío, va después de a
            return dateA - dateB;            // Orden ascendente
        },
        15: (a, b) => a.nivel - b.nivel,
        16: (a, b) => b.nivel - a.nivel,
        17: (a, b) => { if (a.estado && b.estado) return JSON.stringify(a.estado).localeCompare(JSON.stringify(b.estado)) },
        18: (a, b) => { if (b.estado && a.estado) return JSON.stringify(b.estado).localeCompare(JSON.stringify(a.estado)) },
        19: (a, b) => b.capital - a.capital,
        20: (a, b) => a.capital - b.capital,
        21: (a, b) => {
            const dateA = parseDate(a.mesCobrado);
            const dateB = parseDate(b.mesCobrado);
            if (!dateA && !dateB) return 0;  // Ambos vacíos, se mantienen igual
            if (!dateA) return 1;            // a está vacío, va después de b
            if (!dateB) return -1;           // b está vacío, va después de a
            return dateB - dateA
        },
        22: (a, b) => {
            const dateA = parseDate(b.mesCobrado);
            const dateB = parseDate(a.mesCobrado);
            if (!dateA && !dateB) return 0;  // Ambos vacíos, se mantienen igual
            if (!dateA) return 1;            // a está vacío, va después de b
            if (!dateB) return -1;           // b está vacío, va después de a
            return dateB - dateA;
        },
        23: (a, b) => {
            const dateA = parseDate(a.mesEstimado);
            const dateB = parseDate(b.mesEstimado);
            if (!dateA && !dateB) return 0;  // Ambos vacíos, se mantienen igual
            if (!dateA) return 1;            // a está vacío, va después de b
            if (!dateB) return -1;           // b está vacío, va después de a
            return dateB - dateA
        },
        24: (a, b) => {
            const dateA = parseDate(b.mesEstimado);
            const dateB = parseDate(a.mesEstimado);
            if (!dateA && !dateB) return 0;  // Ambos vacíos, se mantienen igual
            if (!dateA) return 1;            // a está vacío, va después de b
            if (!dateB) return -1;           // b está vacío, va después de a
            return dateB - dateA;
        },
        25: (a, b) => b.honorariosFacturado - a.honorariosFacturado,
        26: (a, b) => a.honorariosFacturado - b.honorariosFacturado,
        27: (a, b) => b.hnriosCliente - a.hnriosCliente,
        28: (a, b) => a.hnriosCliente - b.hnriosCliente,
        29: (a, b) => b.honorariosCobro - a.honorariosCobro,
        30: (a, b) => a.honorariosCobro - b.honorariosCobro,
        31: (a, b) => {
            const nameA = (a.compania || '').toString().toLowerCase();
            const nameB = (b.compania || '').toString().toLowerCase();
            if (!nameA && !nameB) return 0;  // Ambos vacíos, se mantienen igual
            if (!nameA) return 1;           // a está vacío, va después de b
            if (!nameB) return -1;          // b está vacío, va después de a
            return nameA.localeCompare(nameB);
        },
        32: (a, b) => {
            const nameA = (b.compania || '').toString().toLowerCase();
            const nameB = (a.compania || '').toString().toLowerCase();
            if (!nameA && !nameB) return 0;  // Ambos vacíos, se mantienen igual
            if (!nameA) return 1;           // a está vacío, va después de b
            if (!nameB) return -1;          // b está vacío, va después de a
            return nameA.localeCompare(nameB);
        },
    };
    const sort = () => {
        const sortFn = sortFunctions[sortA];
        if (sortFn) {
            updatedList.sort(sortFn);
        }
    };
    sort()
    let itemsPerPage = 20
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = updatedList.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(updatedList.length / itemsPerPage);
    const [currentPage, setCurrentPage] = useState(0);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % updatedList.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    }
    const vista = { background: '#FFFFFF 0% 0% no-repeat padding-box' }
    const novista = { background: '#3A4573 0% 0% no-repeat padding-box' }
    const img = { heigth: '44px', width: '44px' }
    const botonSort = { border: 'none', background: 'none' }
    const iconSort = { color: '#FFFFFF' }
    useEffect(() => {
        setItemOffset(0);
        setCurrentPage(0);
    }, [updatedList.length]);
    const filtrar = async () => {
        setInfoTabla('tablaGerencia', JSON.stringify(info), { path: '/' })
    };
    const fieldsToExport = ['nro', 'compania', 'fechaVencimiento', 'fechaExp', 'asignadoA', 'nivel', 'prioridad', 'estado', 'fechaCambioEstado', 'anclado', 'ultimoMensaje', 'notas', 'ultimaMod', 'iconoMediacion'];
    let updatedMovil = expedientes;
    const [inputMovil, setInputMovil] = useState('')
    const filtroMovil = () => {
        if (inputMovil) {
            updatedMovil = updatedMovil.filter((expediente) =>
                JSON.stringify(expediente.nro).includes(inputMovil) ||
                JSON.stringify(expediente.dominioCliente).toLowerCase().includes(JSON.stringify(inputMovil).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '')) ||
                JSON.stringify(expediente.nombre).toLowerCase().includes(JSON.stringify(inputMovil).toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, ''))
            );
        }
    };
    filtroMovil();
    const isMobile375 = useMediaQuery({ query: '(max-width: 375px)' });
    const isMobile412 = useMediaQuery({ query: '(min-width: 376px) and (max-width: 412px)' });
    const isMobile480 = useMediaQuery({ query: '(min-width: 413px) and (max-width: 480px)' });
    const isTablet768 = useMediaQuery({ query: '(min-width: 480px) and (max-width: 900px)' });
    const isPc = useMediaQuery({ query: '(min-width: 900px) and (max-width: 1920px)' });
    const isPcBig = useMediaQuery({ query: '(min-width: 1920px)' })
    return (
        <>
            {
                isPc ?
                    <main className="mainTabla pb-5">
                        <div>
                            {updatedList.length > 0 ? (
                                <>
                                    {info.vista ?
                                        <div className="tablaHead">
                                            <div class="d-flex">
                                                <p className="tituloTabla1366 center">NRO</p>
                                                {sortA === 1 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(2)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 2 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366 center">PATENTE</p>
                                                {sortA === 3 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(4)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 4 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(3)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(3)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}

                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366 center">PAS</p>
                                                {sortA === 5 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(6)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 6 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(5)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(5)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366 center">F.C ESTADO</p>
                                                {sortA === 7 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(8)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 8 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(7)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(7)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366 center">CIA</p>
                                                {sortA === 9 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(10)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 10 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(9)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(9)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366 center">CLIENTE</p>
                                                {sortA === 11 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(12)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 12 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366 center">F VTO</p>
                                                {sortA === 13 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(14)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 14 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(13)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(13)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366 center">NIVEL</p>
                                                {sortA === 15 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(16)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 16 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(15)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(15)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366 center">ESTADO</p>
                                                {sortA === 17 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(18)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 18 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(17)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(17)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                        </div> :
                                        <div className="tablaHeadCobros">
                                            <div class="d-flex">
                                                <p className="tituloTabla1366 center">NRO</p>
                                                {sortA === 1 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(2)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 2 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366  center">FACTURA</p>
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366 center">OFRECIMIENTO</p>
                                                {sortA === 19 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(20)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 20 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(19)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(19)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366  center">COBRADO</p>
                                                {sortA === 21 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(22)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 22 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(21)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(21)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366  center">ESTIMADO</p>
                                                {sortA === 23 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(24)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 24 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(23)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(23)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366  center">CIA</p>
                                                {sortA === 11 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(12)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 12 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            {cookies.user.cargo === 'GERENCIA' ?
                                                <div class="d-flex">
                                                    <p className="tituloTabla1366 center">H FACT</p>
                                                    {sortA === 25 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(26)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                        : (sortA === 26 ?
                                                            <button style={botonSort} onClick={(e) => setSortA(25)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                            :
                                                            <button style={botonSort} onClick={(e) => setSortA(25)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                        )}
                                                </div>
                                                : null}
                                            <div class="d-flex">
                                                <p className="tituloTabla1366  center">H CLIENTE</p>
                                                {sortA === 27 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(28)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 28 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(27)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(27)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366  center">H COBRO</p>
                                                {sortA === 29 ?
                                                    <button style={botonSort} onClick={(e) => setSortA(30)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                    : (sortA === 30 ?
                                                        <button style={botonSort} onClick={(e) => setSortA(29)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                        :
                                                        <button style={botonSort} onClick={(e) => setSortA(29)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                    )}
                                            </div>
                                            <div class="d-flex">
                                                <p className="tituloTabla1366  center">EST COBRO</p>
                                            </div>
                                        </div>}
                                    {info.vista ? <div className="mt-2">{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                        return (
                                            <Expediente
                                                expediente={expediente}
                                                key={expediente.id}
                                            />
                                        );
                                    })}</div> : <div className="mt-2">{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                        return (
                                            <Cobros
                                                expediente={expediente}
                                                key={expediente.id}
                                            />
                                        );
                                    })}</div>}
                                </>
                            ) : (
                                <div className="">
                                    {(infoTabla.tablaGerencia.pas.length === 0 &&
                                        infoTabla.tablaGerencia.asignado.length === 0 &&
                                        infoTabla.tablaGerencia.comp.length === 0 &&
                                        infoTabla.tablaGerencia.estado.length === 0 &&
                                        infoTabla.tablaGerencia.fecha.length === 0 &&
                                        !infoTabla.tablaGerencia.info &&
                                        !infoTabla.tablaGerencia.cliente &&
                                        !infoTabla.tablaGerencia.patente &&
                                        infoTabla.tablaGerencia.nivel.length === 0) ?
                                        <div className="center mt-5 pt-5">
                                            <div class="spinner-border text-primary center" role="status"></div>
                                        </div> :
                                        <div className="table-responsive scrollbar divTabla">
                                            <h2 className="center letra mt-5">SIN RESULTADOS</h2>
                                        </div>}
                                </div>
                            )}
                            <div className="center mt-5">
                                <div className="center mt-5">
                                    <Excel data={updatedList} />
                                    <ExcelResumen data={updatedList} fields={fieldsToExport} />
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <div className="filtroTicketContainer pt-4 me-1" style={info.vista ? vista : novista}>
                                <div className="d-flex ps-3">
                                    <div className="">
                                        <button onClick={(e) => filtrar()} style={btn}><img src={iconoFiltrar} alt="filtrar" style={img} />FILTRAR</button>
                                    </div>
                                    <div className="">
                                        <button onClick={(e) => vaciar()} style={btn}><img src={iconoGlobal} alt="vaciar" style={img}></img>VACIAR</button>
                                    </div>
                                    {info.vista ?
                                        <div className="">
                                            <button style={btn} onClick={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    vista: false,
                                                }))
                                                setSortA({ nro: 0, patente: 0, pas: 0, cia: 0, cliente: 0, capital: 0, comp: 0, n: 0, fact: 0, cob: 0, cli: 0, cobro: 0, est: 0, cobrado: 0, stro: 0 })
                                            }
                                            }><img src={iconoCobro} style={img}></img>COBROS</button>
                                        </div> :
                                        <div>
                                            <button style={btn} onClick={(e) => {
                                                setInfo((prevState) => ({ ...prevState, vista: true, }))
                                                setSortA({ nro: 0, patente: 0, pas: 0, cia: 0, cliente: 0, capital: 0, comp: 0, n: 0, fact: 0, cob: 0, cli: 0, cobro: 0, est: 0, cobrado: 0, vto: 0 })
                                            }
                                            }><img src={iconoExpediente} alt="cobro" style={img}></img>EXPEDIENTES</button>
                                        </div>}
                                </div>
                                <form className="me-3 ms-3 mt-3" onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                                    <div class="">
                                        <input
                                            placeholder="BUSQUEDA GENERAL"
                                            type="text"
                                            class="inputsDelFiltroSearch pe-3"
                                            onChange={(e) =>
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    info: e.target.value,
                                                }))
                                            }
                                            value={info.info}
                                        />
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 pe-1">
                                            <h6 className="subtituloDelFiltro m-0">Asignado</h6>
                                            <MultiSelect
                                                options={asignadoInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                value={info.asignado}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        asignado: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 ps-1">
                                            <h6 className="subtituloDelFiltro m-0">Estado</h6>
                                            <MultiSelect
                                                options={estadoInput}
                                                value={info.estado}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        estado: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6 pe-1">
                                            <h6 className="subtituloDelFiltro m-0">Nivel</h6>
                                            <MultiSelect
                                                className=""
                                                options={nivelInput}
                                                value={info.nivel}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        nivel: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 ps-1">
                                            <h6 className="subtituloDelFiltro m-0">Vencimiento</h6>
                                            <MultiSelect
                                                className=""
                                                options={fechaInput}
                                                value={info.fecha}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        fecha: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6 pe-1">
                                            <h6 className="subtituloDelFiltro m-0">Compañias</h6>
                                            <MultiSelect
                                                className=""
                                                options={compInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                value={info.comp}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        comp: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 ps-1">
                                            <h6 className="subtituloDelFiltro m-0">Productores</h6>
                                            <MultiSelect
                                                className=""
                                                options={proInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                value={info.pas}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        pas: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                </form>
                                <div className="me-3 ms-3">
                                    <div className="mt-2">
                                        <h6 className="subtituloDelFiltro m-0">Mes Cobrado</h6>
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <input type="month" class="inputsDelFiltro" value={info.mesCobradoFrom}
                                                    placeholder={infoTabla.tablaGerencia.mesCobradoFrom}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            mesCobradoFrom: e.target.value,
                                                        }))} />
                                            </div>
                                            <div className="col-6 ps-1">
                                                <input type="month" class="inputsDelFiltro" value={info.mesCobradoTo} placeholder={infoTabla.tablaGerencia.mesCobradoTo}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            mesCobradoTo: e.target.value,
                                                        }))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <h6 className="subtituloDelFiltro m-0">Mes Estimado</h6>
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <input type="month" class="inputsDelFiltro" value={info.mesEstimadoFrom} placeholder={infoTabla.tablaGerencia.mesEstimadoFrom}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            mesEstimadoFrom: e.target.value,
                                                        }))} />
                                            </div>
                                            <div className="col-6 ps-1">
                                                <input type="month" class="inputsDelFiltro" value={info.mesEstimadoTo} placeholder={infoTabla.tablaGerencia.mesCobradoTo}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            mesEstimadoTo: e.target.value,
                                                        }))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <h6 className="subtituloDelFiltro m-0">Fecha Exp</h6>
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <input type="date" class="inputsDelFiltro" value={info.expFrom} placeholder={infoTabla.tablaGerencia.expFrom}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            expFrom: e.target.value,
                                                        }))} />
                                            </div>
                                            <div className="col-6 ps-1">
                                                <input type="date" class="inputsDelFiltro" value={info.expTo} placeholder={infoTabla.tablaGerencia.expTo}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            expTo: e.target.value,
                                                        }))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <h6 className="subtituloDelFiltro m-0">Nro</h6>
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <input type="number" class="inputsDelFiltro" value={info.nroFrom} placeholder={infoTabla.tablaGerencia.nroFrom}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            nroFrom: e.target.value,
                                                        }))} />
                                            </div>
                                            <div className="col-6 ps-1">
                                                <input type="number" class="inputsDelFiltro" value={info.nroTo} placeholder={infoTabla.tablaGerencia.nroTo}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            nroTo: e.target.value,
                                                        }))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 row">
                                        <div className="col-6 pe-1">
                                            <h6 className="subtituloDelFiltro m-0">Modo de Pago</h6>
                                            <select
                                                className="inputsDelFiltro" value={info.modoDePago}
                                                onChange={(e) =>
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        modoDePago: e.target.value,
                                                    }))
                                                }
                                            >
                                                <option selected hidden>
                                                    {infoTabla.tablaGerencia.modoDePago}
                                                </option>
                                                <option value="">
                                                    {" "}
                                                </option>
                                                {pagoInput.map((comp) => (
                                                    <option key={comp} value={comp}>
                                                        {comp}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-6 ps-1">
                                            <h6 className="subtituloDelFiltro m-0">Estado Cobro</h6>
                                            <select
                                                className="inputsDelFiltro" value={info.estadoCobro}
                                                onChange={(e) =>
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        estadoCobro: e.target.value,
                                                    }))
                                                }
                                            >
                                                <option selected hidden>
                                                    {infoTabla.tablaGerencia.estadoCobro}
                                                </option>
                                                <option value="" >
                                                    {" "}
                                                </option>
                                                {estadoCobroInput.map((pro) => (
                                                    <option key={pro} value={pro}>
                                                        {pro}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6 pe-1">
                                            <h6 className="subtituloDelFiltro m-0">Nro Factura</h6>
                                            <input type="number" class="inputsDelFiltro" value={info.nroFactura} placeholder={infoTabla.tablaGerencia.nroFactura}
                                                onChange={(e) =>
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        nroFactura: e.target.value,
                                                    }))} />
                                        </div>
                                        <div className="col-6 ps-1">
                                            <h6 className="subtituloDelFiltro m-0">Facturado Por</h6>
                                            <select
                                                className="w-100 inputsDelFiltro" value={info.facturadoPor}
                                                onChange={(e) =>
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        facturadoPor: e.target.value,
                                                    }))
                                                }
                                            >
                                                <option selected hidden>
                                                    {infoTabla.tablaGerencia.facturadoPor}
                                                </option>
                                                <option value="">
                                                    {" "}
                                                </option>
                                                {facturadoInput.map((pro) => (
                                                    <option key={pro} value={pro}>
                                                        {pro}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    {cookies.user.cargo === 'GERENCIA' ?
                                        <div className="mt-2">
                                            <h6 className="subtituloDelFiltro m-0">Hnrios Facturados</h6>
                                            <div className="row">
                                                <div className="col-6 pe-1">
                                                    <input type="number" class="inputsDelFiltro" value={info.honorariosFrom} placeholder={infoTabla.tablaGerencia.honorariosFrom}
                                                        onChange={(e) =>
                                                            setInfo((prevState) => ({
                                                                ...prevState,
                                                                honorariosFrom: e.target.value,
                                                            }))} />
                                                </div>
                                                <div className="col-6 ps-1">
                                                    <input type="number" class="inputsDelFiltro" value={info.honorariosTo} placeholder={infoTabla.tablaGerencia.honorariosTo}
                                                        onChange={(e) =>
                                                            setInfo((prevState) => ({
                                                                ...prevState,
                                                                honorariosTo: e.target.value,
                                                            }))} />
                                                </div>
                                            </div>
                                        </div> : null}
                                    <div className="mt-4 center">
                                        <ReactPaginate
                                            className='center p-0'
                                            forcePage={currentPage}
                                            activeClassName={info.vista ? 'item active' : 'item active text-white'}
                                            breakClassName={info.vista ? 'item break-me' : 'item break-me text-white'}
                                            breakLabel={'...'}
                                            marginPagesDisplayed={1}
                                            onPageChange={handlePageClick}
                                            pageClassName={info.vista ? 'item pagination-page' : 'item pagination-page text-white'}
                                            pageRangeDisplayed={5}
                                            pageCount={pageCount}
                                        />
                                    </div>
                                    <h6 className={info.vista ? 'center' : 'center text-white'}>Cantidad de expedientes: {updatedList.length}</h6>
                                </div>
                            </div>
                        </div>
                    </main >
                    : isMobile375 ?
                        <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                            <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                            <div className="center" style={{ marginTop: '3vh' }}>
                                <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                            </div>
                            <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                            <div style={{ marginTop: '3vh' }}>
                                <div className="row mb-3 pe-3 ps-3">
                                    <div className="textoHeaderTablaMovil col">NRO</div>
                                    <div className="textoHeaderTablaMovil col">PATENTE</div>
                                    <div className="textoHeaderTablaMovil col">F.STRO</div>
                                    <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                    <div className="textoHeaderTablaMovil col">ESTADO</div>
                                </div>
                                {updatedMovil?.slice(0, 100).map(e => (
                                    <ExpedienteTablaMovil key={e.id} expediente={e} />
                                ))}

                            </div>
                        </main>
                        : isMobile412 ?
                            <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                                <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                                <div className="center" style={{ marginTop: '3vh' }}>
                                    <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                                </div>
                                <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                                <div style={{ marginTop: '3vh' }}>
                                    <div className="row mb-3 pe-3 ps-3">
                                        <div className="textoHeaderTablaMovil col">NRO</div>
                                        <div className="textoHeaderTablaMovil col">PATENTE</div>
                                        <div className="textoHeaderTablaMovil col">F.STRO</div>
                                        <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                        <div className="textoHeaderTablaMovil col">ESTADO</div>
                                    </div>
                                    {updatedMovil?.slice(0, 100).map(e => (
                                        <ExpedienteTablaMovil key={e.id} expediente={e} />
                                    ))}

                                </div>
                            </main>
                            : isMobile480 ?
                                <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                                    <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                                    <div className="center" style={{ marginTop: '3vh' }}>
                                        <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                                    </div>
                                    <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                                    <div style={{ marginTop: '3vh' }}>
                                        <div className="row mb-3 pe-3 ps-3">
                                            <div className="textoHeaderTablaMovil col">NRO</div>
                                            <div className="textoHeaderTablaMovil col">PATENTE</div>
                                            <div className="textoHeaderTablaMovil col">F.STRO</div>
                                            <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                            <div className="textoHeaderTablaMovil col">ESTADO</div>
                                        </div>
                                        {updatedMovil?.slice(0, 100).map(e => (
                                            <ExpedienteTablaMovil key={e.id} expediente={e} />
                                        ))}

                                    </div>
                                </main>
                                : isTablet768 ?
                                    <main style={{ paddingLeft: '3%', paddingRight: '3%' }}>
                                        <h3 className="textoTablaMovil center" style={{ paddingTop: '13vh' }}>INGRESE EXPEDIENTE<br /> PATENTE O NOMBRE</h3>
                                        <div className="center" style={{ marginTop: '3vh' }}>
                                            <input type="text" className="center text-center inputBuscarTablaMovil" onChange={(e) => setInputMovil(e.target.value)} value={inputMovil} />
                                        </div>
                                        <div className="center" style={{ marginTop: '3vh' }}><button className="btnBuscarTablaMovil">BUSCAR</button></div>
                                        <div style={{ marginTop: '3vh' }}>
                                            <div className="row mb-3 pe-3 ps-3">
                                                <div className="textoHeaderTablaMovil col">NRO</div>
                                                <div className="textoHeaderTablaMovil col">PATENTE</div>
                                                <div className="textoHeaderTablaMovil col">F.STRO</div>
                                                <div className="textoHeaderTablaMovil col">COMPAÑIA</div>
                                                <div className="textoHeaderTablaMovil col">ESTADO</div>
                                            </div>
                                            {updatedMovil?.slice(0, 100).map(e => (
                                                <ExpedienteTablaMovil key={e.id} expediente={e} />
                                            ))}

                                        </div>
                                    </main>
                                    :
                                    <main className="mainTabla pb-5">
                                        <div>
                                            {updatedList.length > 0 ? (
                                                <>
                                                    {info.vista ?
                                                        <div className="tablaHead">
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">NRO</p>
                                                                {sortA === 1 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(2)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 2 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">PATENTE</p>
                                                                {sortA === 3 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(4)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 4 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(3)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(3)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}

                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">PAS</p>
                                                                {sortA === 5 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(6)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 6 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(5)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(5)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">F.C ESTADO</p>
                                                                {sortA === 7 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(8)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 8 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(7)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(7)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">CIA</p>
                                                                {sortA === 9 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(10)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 10 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(9)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(9)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">CLIENTE</p>
                                                                {sortA === 11 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(12)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 12 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(11)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">F VTO</p>
                                                                {sortA === 13 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(14)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 14 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(13)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(13)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">NIVEL</p>
                                                                {sortA === 15 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(16)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 16 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(15)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(15)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">ESTADO</p>
                                                                {sortA === 17 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(18)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 18 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(17)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(17)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                        </div> :
                                                        <div className="tablaHeadCobros">
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">NRO</p>
                                                                {sortA === 1 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(2)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 2 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(1)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">FACTURA</p>
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">OFRECIMIENTO</p>
                                                                {sortA === 19 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(20)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 20 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(19)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(19)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">COBRADO</p>
                                                                {sortA === 21 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(22)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 22 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(21)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(21)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">ESTIMADO</p>
                                                                {sortA === 23 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(24)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 24 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(23)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(23)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">CIA</p>
                                                                {sortA === 31 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(32)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 32 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(31)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(31)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            {cookies.user.cargo === 'GERENCIA' ?
                                                                <div class="d-flex">
                                                                    <p className="tituloTablaBigScreen center">H FACT</p>
                                                                    {sortA === 25 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(26)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                        : (sortA === 26 ?
                                                                            <button style={botonSort} onClick={(e) => setSortA(25)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                            :
                                                                            <button style={botonSort} onClick={(e) => setSortA(25)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                        )}
                                                                </div>
                                                                : null}
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">H CLIENTE</p>
                                                                {sortA === 27 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(28)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 28 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(27)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(27)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">H COBRO</p>
                                                                {sortA === 29 ?
                                                                    <button style={botonSort} onClick={(e) => setSortA(30)}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                                    : (sortA === 30 ?
                                                                        <button style={botonSort} onClick={(e) => setSortA(29)}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                                        :
                                                                        <button style={botonSort} onClick={(e) => setSortA(29)}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                                    )}
                                                            </div>
                                                            <div class="d-flex">
                                                                <p className="tituloTablaBigScreen center">EST COBRO</p>
                                                            </div>
                                                        </div>}
                                                    {info.vista ? <div className="mt-2">{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                                        return (
                                                            <Expediente
                                                                expediente={expediente}
                                                                key={expediente.id}
                                                            />
                                                        );
                                                    })}</div> : <div className="mt-2">{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                                        return (
                                                            <Cobros
                                                                expediente={expediente}
                                                                key={expediente.id}
                                                            />
                                                        );
                                                    })}</div>}
                                                </>
                                            ) : (
                                                <div className="">
                                                    {(infoTabla.tablaGerencia.pas.length === 0 &&
                                                        infoTabla.tablaGerencia.asignado.length === 0 &&
                                                        infoTabla.tablaGerencia.comp.length === 0 &&
                                                        infoTabla.tablaGerencia.estado.length === 0 &&
                                                        infoTabla.tablaGerencia.fecha.length === 0 &&
                                                        !infoTabla.tablaGerencia.info &&
                                                        !infoTabla.tablaGerencia.cliente &&
                                                        !infoTabla.tablaGerencia.patente &&
                                                        infoTabla.tablaGerencia.nivel.length === 0) ?
                                                        <div className="center mt-5 pt-5">
                                                            <div class="spinner-border text-primary center" role="status"></div>
                                                        </div> :
                                                        <div className="table-responsive scrollbar divTabla">
                                                            <h2 className="center letra mt-5">SIN RESULTADOS</h2>
                                                        </div>}
                                                </div>
                                            )}
                                            <div className="center mt-5">
                                                <div className="center mt-5">
                                                    <Excel data={updatedList} />
                                                    <ExcelResumen data={updatedList} fields={fieldsToExport} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="">
                                            <div className="filtroTicketContainer pt-4 me-1" style={info.vista ? vista : novista}>
                                                <div className="d-flex ps-3">
                                                    <div className="">
                                                        <button onClick={(e) => filtrar()} style={btn}><img src={iconoFiltrar} alt="filtrar" style={img} />FILTRAR</button>
                                                    </div>
                                                    <div className="">
                                                        <button onClick={(e) => vaciar()} style={btn}><img src={iconoGlobal} alt="vaciar" style={img}></img>VACIAR</button>
                                                    </div>
                                                    {info.vista ?
                                                        <div className="">
                                                            <button style={btn} onClick={(e) => {
                                                                setInfo((prevState) => ({
                                                                    ...prevState,
                                                                    vista: false,
                                                                }))
                                                                setSortA({ nro: 0, patente: 0, pas: 0, cia: 0, cliente: 0, capital: 0, comp: 0, n: 0, fact: 0, cob: 0, cli: 0, cobro: 0, est: 0, cobrado: 0, stro: 0 })
                                                            }
                                                            }><img src={iconoCobro} style={img}></img>COBROS</button>
                                                        </div> :
                                                        <div>
                                                            <button style={btn} onClick={(e) => {
                                                                setInfo((prevState) => ({ ...prevState, vista: true, }))
                                                                setSortA({ nro: 0, patente: 0, pas: 0, cia: 0, cliente: 0, capital: 0, comp: 0, n: 0, fact: 0, cob: 0, cli: 0, cobro: 0, est: 0, cobrado: 0, vto: 0 })
                                                            }
                                                            }><img src={iconoExpediente} alt="cobro" style={img}></img>EXPEDIENTES</button>
                                                        </div>}
                                                </div>
                                                <form className="me-3 ms-3 mt-3" onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                                                    <div class="">
                                                        <input
                                                            placeholder="BUSQUEDA GENERAL"
                                                            type="text"
                                                            class="inputsDelFiltroSearch pe-3"
                                                            onChange={(e) =>
                                                                setInfo((prevState) => ({
                                                                    ...prevState,
                                                                    info: e.target.value,
                                                                }))
                                                            }
                                                            value={info.info}
                                                        />
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-6 pe-1">
                                                            <h6 className="subtituloDelFiltro m-0">Asignado</h6>
                                                            <MultiSelect
                                                                options={asignadoInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                                value={info.asignado}
                                                                onChange={(e) => {
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        asignado: e
                                                                    }))
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-6 ps-1">
                                                            <h6 className="subtituloDelFiltro m-0">Estado</h6>
                                                            <MultiSelect
                                                                options={estadoInput}
                                                                value={info.estado}
                                                                onChange={(e) => {
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        estado: e
                                                                    }))
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-6 pe-1">
                                                            <h6 className="subtituloDelFiltro m-0">Nivel</h6>
                                                            <MultiSelect
                                                                className=""
                                                                options={nivelInput}
                                                                value={info.nivel}
                                                                onChange={(e) => {
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        nivel: e
                                                                    }))
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-6 ps-1">
                                                            <h6 className="subtituloDelFiltro m-0">Vencimiento</h6>
                                                            <MultiSelect
                                                                className=""
                                                                options={fechaInput}
                                                                value={info.fecha}
                                                                onChange={(e) => {
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        fecha: e
                                                                    }))
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-6 pe-1">
                                                            <h6 className="subtituloDelFiltro m-0">Compañias</h6>
                                                            <MultiSelect
                                                                className=""
                                                                options={compInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                                value={info.comp}
                                                                onChange={(e) => {
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        comp: e
                                                                    }))
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="col-6 ps-1">
                                                            <h6 className="subtituloDelFiltro m-0">Productores</h6>
                                                            <MultiSelect
                                                                className=""
                                                                options={proInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                                value={info.pas}
                                                                onChange={(e) => {
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        pas: e
                                                                    }))
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </form>
                                                <div className="me-3 ms-3">
                                                    <div className="mt-2">
                                                        <h6 className="subtituloDelFiltro m-0">Mes Cobrado</h6>
                                                        <div className="row">
                                                            <div className="col-6 pe-1">
                                                                <input type="month" class="inputsDelFiltro" value={info.mesCobradoFrom}
                                                                    placeholder={infoTabla.tablaGerencia.mesCobradoFrom}
                                                                    onChange={(e) =>
                                                                        setInfo((prevState) => ({
                                                                            ...prevState,
                                                                            mesCobradoFrom: e.target.value,
                                                                        }))} />
                                                            </div>
                                                            <div className="col-6 ps-1">
                                                                <input type="month" class="inputsDelFiltro" value={info.mesCobradoTo} placeholder={infoTabla.tablaGerencia.mesCobradoTo}
                                                                    onChange={(e) =>
                                                                        setInfo((prevState) => ({
                                                                            ...prevState,
                                                                            mesCobradoTo: e.target.value,
                                                                        }))} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <h6 className="subtituloDelFiltro m-0">Mes Estimado</h6>
                                                        <div className="row">
                                                            <div className="col-6 pe-1">
                                                                <input type="month" class="inputsDelFiltro" value={info.mesEstimadoFrom} placeholder={infoTabla.tablaGerencia.mesEstimadoFrom}
                                                                    onChange={(e) =>
                                                                        setInfo((prevState) => ({
                                                                            ...prevState,
                                                                            mesEstimadoFrom: e.target.value,
                                                                        }))} />
                                                            </div>
                                                            <div className="col-6 ps-1">
                                                                <input type="month" class="inputsDelFiltro" value={info.mesEstimadoTo} placeholder={infoTabla.tablaGerencia.mesCobradoTo}
                                                                    onChange={(e) =>
                                                                        setInfo((prevState) => ({
                                                                            ...prevState,
                                                                            mesEstimadoTo: e.target.value,
                                                                        }))} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <h6 className="subtituloDelFiltro m-0">Fecha Exp</h6>
                                                        <div className="row">
                                                            <div className="col-6 pe-1">
                                                                <input type="date" class="inputsDelFiltro" value={info.expFrom} placeholder={infoTabla.tablaGerencia.expFrom}
                                                                    onChange={(e) =>
                                                                        setInfo((prevState) => ({
                                                                            ...prevState,
                                                                            expFrom: e.target.value,
                                                                        }))} />
                                                            </div>
                                                            <div className="col-6 ps-1">
                                                                <input type="date" class="inputsDelFiltro" value={info.expTo} placeholder={infoTabla.tablaGerencia.expTo}
                                                                    onChange={(e) =>
                                                                        setInfo((prevState) => ({
                                                                            ...prevState,
                                                                            expTo: e.target.value,
                                                                        }))} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2">
                                                        <h6 className="subtituloDelFiltro m-0">Nro</h6>
                                                        <div className="row">
                                                            <div className="col-6 pe-1">
                                                                <input type="number" class="inputsDelFiltro" value={info.nroFrom} placeholder={infoTabla.tablaGerencia.nroFrom}
                                                                    onChange={(e) =>
                                                                        setInfo((prevState) => ({
                                                                            ...prevState,
                                                                            nroFrom: e.target.value,
                                                                        }))} />
                                                            </div>
                                                            <div className="col-6 ps-1">
                                                                <input type="number" class="inputsDelFiltro" value={info.nroTo} placeholder={infoTabla.tablaGerencia.nroTo}
                                                                    onChange={(e) =>
                                                                        setInfo((prevState) => ({
                                                                            ...prevState,
                                                                            nroTo: e.target.value,
                                                                        }))} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="mt-2 row">
                                                        <div className="col-6 pe-1">
                                                            <h6 className="subtituloDelFiltro m-0">Modo de Pago</h6>
                                                            <select
                                                                className="inputsDelFiltro" value={info.modoDePago}
                                                                onChange={(e) =>
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        modoDePago: e.target.value,
                                                                    }))
                                                                }
                                                            >
                                                                <option selected hidden>
                                                                    {infoTabla.tablaGerencia.modoDePago}
                                                                </option>
                                                                <option value="">
                                                                    {" "}
                                                                </option>
                                                                {pagoInput.map((comp) => (
                                                                    <option key={comp} value={comp}>
                                                                        {comp}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        <div className="col-6 ps-1">
                                                            <h6 className="subtituloDelFiltro m-0">Estado Cobro</h6>
                                                            <select
                                                                className="inputsDelFiltro" value={info.estadoCobro}
                                                                onChange={(e) =>
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        estadoCobro: e.target.value,
                                                                    }))
                                                                }
                                                            >
                                                                <option selected hidden>
                                                                    {infoTabla.tablaGerencia.estadoCobro}
                                                                </option>
                                                                <option value="" >
                                                                    {" "}
                                                                </option>
                                                                {estadoCobroInput.map((pro) => (
                                                                    <option key={pro} value={pro}>
                                                                        {pro}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-2">
                                                        <div className="col-6 pe-1">
                                                            <h6 className="subtituloDelFiltro m-0">Nro Factura</h6>
                                                            <input type="number" class="inputsDelFiltro" value={info.nroFactura} placeholder={infoTabla.tablaGerencia.nroFactura}
                                                                onChange={(e) =>
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        nroFactura: e.target.value,
                                                                    }))} />
                                                        </div>
                                                        <div className="col-6 ps-1">
                                                            <h6 className="subtituloDelFiltro m-0">Facturado Por</h6>
                                                            <select
                                                                className="w-100 inputsDelFiltro" value={info.facturadoPor}
                                                                onChange={(e) =>
                                                                    setInfo((prevState) => ({
                                                                        ...prevState,
                                                                        facturadoPor: e.target.value,
                                                                    }))
                                                                }
                                                            >
                                                                <option selected hidden>
                                                                    {infoTabla.tablaGerencia.facturadoPor}
                                                                </option>
                                                                <option value="">
                                                                    {" "}
                                                                </option>
                                                                {facturadoInput.map((pro) => (
                                                                    <option key={pro} value={pro}>
                                                                        {pro}
                                                                    </option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    {cookies.user.cargo === 'GERENCIA' ?
                                                        <div className="mt-2">
                                                            <h6 className="subtituloDelFiltro m-0">Hnrios Facturados</h6>
                                                            <div className="row">
                                                                <div className="col-6 pe-1">
                                                                    <input type="number" class="inputsDelFiltro" value={info.honorariosFrom} placeholder={infoTabla.tablaGerencia.honorariosFrom}
                                                                        onChange={(e) =>
                                                                            setInfo((prevState) => ({
                                                                                ...prevState,
                                                                                honorariosFrom: e.target.value,
                                                                            }))} />
                                                                </div>
                                                                <div className="col-6 ps-1">
                                                                    <input type="number" class="inputsDelFiltro" value={info.honorariosTo} placeholder={infoTabla.tablaGerencia.honorariosTo}
                                                                        onChange={(e) =>
                                                                            setInfo((prevState) => ({
                                                                                ...prevState,
                                                                                honorariosTo: e.target.value,
                                                                            }))} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : null}
                                                    <div className="mt-4 center">
                                                        <ReactPaginate
                                                            className='center p-0'
                                                            forcePage={currentPage}
                                                            activeClassName={info.vista ? 'item active' : 'item active text-white'}
                                                            breakClassName={info.vista ? 'item break-me' : 'item break-me text-white'}
                                                            breakLabel={'...'}
                                                            marginPagesDisplayed={1}
                                                            onPageChange={handlePageClick}
                                                            pageClassName={info.vista ? 'item pagination-page' : 'item pagination-page text-white'}
                                                            pageRangeDisplayed={5}
                                                            pageCount={pageCount}
                                                        />
                                                    </div>
                                                    <h6 className={info.vista ? 'center' : 'center text-white'}>Cantidad de expedientes: {updatedList.length}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </main >
                /*<main className="mainTabla pb-5">
                    <section className="row sectionExpedientesGral">
                        <div className="col-10">
                            {preCarga === true ? (updatedList.length > 0 ?
                                <div class="ms-2">
                                    {info.vista ?
                                        <div className="tablaHead row ps-3 m-0">
                                            {sortA.sort === 'NRO ↑' ?
                                                <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-2">NRO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↓' }))}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'NRO ↓' ?
                                                <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-2">NRO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'NRO ↑' && sortA.sort !== 'NRO ↓' ?
                                                <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-2">NRO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'PATENTE ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla">PATENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla">PATENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'PATENTE ↑' && sortA.sort !== 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla">PATENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'PAS ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">PAS</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">PAS</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'PAS ↑' && sortA.sort !== 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">PAS</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'FECHA ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'FECHA ↑' && sortA.sort !== 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'COMPAÑIA ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">CIA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">CIA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'COMPAÑIA ↑' && sortA.sort !== 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">CIA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}


                                            {sortA.sort === 'CLIENTE ↑' ? <div class="col-3 p-0 d-flex">
                                                <p className="tituloTabla ps-1">CLIENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                <p className="tituloTabla ps-1">CLIENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'CLIENTE ↑' && sortA.sort !== 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                <p className="tituloTabla ps-1">CLIENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}


                                            {sortA.sort === 'VTO ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F VTO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F VTO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'VTO ↑' && sortA.sort !== 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F VTO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'NIVEL ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">NIVEL</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↓' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'NIVEL ↓' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">NIVEL</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'NIVEL ↑' && sortA.sort !== 'NIVEL ↓' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">NIVEL</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NIVEL ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}


                                            {sortA.sort === 'ESTADO ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'ESTADO ↑' && sortA.sort !== 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                        </div> :
                                        <div className="tablaHead row ps-3 m-0">
                                            {sortA.sort === 'NRO ↑' ?
                                                <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-2">NRO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↓' }))}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'NRO ↓' ?
                                                <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-2">NRO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'NRO ↑' && sortA.sort !== 'NRO ↓' ?
                                                <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-2">NRO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'CAPITAL ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla">CAPITAL</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CAPITAL ↓' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'CAPITAL ↓' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla">CAPITAL</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CAPITAL ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'CAPITAL ↑' && sortA.sort !== 'CAPITAL ↓' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla">CAPITAL</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CAPITAL ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'COBRADO ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">COBRADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COBRADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'COBRADO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">COBRADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COBRADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'COBRADO ↑' && sortA.sort !== 'COBRADO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">COBRADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COBRADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'ESTIMADO ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">ESTIMADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTIMADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'ESTIMADO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">ESTIMADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTIMADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'ESTIMADO ↑' && sortA.sort !== 'ESTIMADO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">ESTIMADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTIMADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'COMPAÑIA ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">CIA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">CIA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'COMPAÑIA ↑' && sortA.sort !== 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">CIA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'H FACT ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">H FACT</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'H FACT ↓' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'H FACT ↓' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">H FACT</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'H FACT ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'H FACT ↑' && sortA.sort !== 'H FACT ↓' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">H FACT</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'H FACT ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'H CLIENTE ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">H CLIENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'H CLIENTE ↓' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'H CLIENTE ↓' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">H CLIENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'H CLIENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'H CLIENTE ↑' && sortA.sort !== 'H CLIENTE ↓' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">H CLIENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'H CLIENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'H COBRO ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">H COBRO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'H COBRO ↓' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'H COBRO ↓' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">H COBRO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'H COBRO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'H COBRO ↑' && sortA.sort !== 'H COBRO ↓' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">H COBRO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'H COBRO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'COBRO ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">EST COBRO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COBRO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'COBRO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">EST COBRO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COBRO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'COBRO ↑' && sortA.sort !== 'COBRO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">EST COBRO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COBRO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'FACTURA ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">FACTURA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FACTURA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'FACTURA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">FACTURA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FACTURA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'FACTURA ↑' && sortA.sort !== 'FACTURA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">FACTURA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FACTURA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'FACT POR ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">FACT POR</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FACT POR ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'FACT POR ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">FACT POR</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FACT POR ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'FACT POR ↑' && sortA.sort !== 'FACT POR ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">FACT POR</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FACT POR ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                        </div>}
                                    {info.vista ? <div className="mt-1" style={aka}>{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                        return (
                                            <Expediente
                                                expediente={expediente}
                                                key={expediente.id}
                                            />
                                        );
                                    })}</div> : <div className="mt-1">{updatedList.slice(itemOffset, Number(itemOffset + 20)).map((expediente) => {
                                        return (
                                            <Cobros
                                                expediente={expediente}
                                                key={expediente.id}
                                            />
                                        );
                                    })}</div>}
                                </div>
                                :
                                <div className="">
                                    <div className="table-responsive scrollbar divTabla">
                                        <div className="tablaHead row ps-3 m-0">
                                            {sortA.sort === 'NRO ↑' ?
                                                <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-2">NRO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↓' }))}><i class="bi bi-sort-up" style={iconSort} ></i></button>
                                                </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'NRO ↓' ?
                                                <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-2">NRO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-sort-down" style={iconSort} ></i></button>
                                                </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'NRO ↑' && sortA.sort !== 'NRO ↓' ?
                                                <div class="col p-0 d-flex">
                                                    <p className="tituloTabla ps-2">NRO</p>
                                                    <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'NRO ↑' }))}><i class="bi bi-filter" style={iconSort} ></i></button>
                                                </div> : <div className="d-none"></div>}


                                            {sortA.sort === 'PATENTE ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla">PATENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla">PATENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'PATENTE ↑' && sortA.sort !== 'PATENTE ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla">PATENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PATENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'PAS ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">PAS</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">PAS</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'PAS ↑' && sortA.sort !== 'PAS ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">PAS</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'PAS ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'FECHA ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'FECHA ↑' && sortA.sort !== 'FECHA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F.C ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'FECHA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            {sortA.sort === 'COMPAÑIA ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">CIA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">CIA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'COMPAÑIA ↑' && sortA.sort !== 'COMPAÑIA ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">CIA</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'COMPAÑIA ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}


                                            {sortA.sort === 'CLIENTE ↑' ? <div class="col-3 p-0 d-flex">
                                                <p className="tituloTabla ps-1">CLIENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                <p className="tituloTabla ps-1">CLIENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'CLIENTE ↑' && sortA.sort !== 'CLIENTE ↓ZA' ? <div class="col-3 p-0 d-flex">
                                                <p className="tituloTabla ps-1">CLIENTE</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'CLIENTE ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}


                                            {sortA.sort === 'VTO ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F VTO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F VTO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'VTO ↑' && sortA.sort !== 'VTO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">F VTO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'VTO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}

                                            <div className="col p-0 d-flex"></div>
                                            {sortA.sort === 'ESTADO ↑' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↓ZA' }))}><i class="bi bi-sort-up" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort === 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-sort-down" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                            {sortA.sort !== 'ESTADO ↑' && sortA.sort !== 'ESTADO ↓ZA' ? <div class="col p-0 d-flex">
                                                <p className="tituloTabla ps-1">ESTADO</p>
                                                <button style={botonSort} onClick={(e) => setSortA((prevState) => ({ sort: 'ESTADO ↑' }))}><i class="bi bi-filter" style={iconSort}></i></button>
                                            </div> : <div className="d-none"></div>}
                                        </div>
                                        <h2 className="center letra mt-5">SIN RESULTADOS</h2>
                                    </div>
                                </div>
                            ) : <div className="center mt-5 pt-5">
                                <div class="spinner-border text-primary center" role="status"></div>
                            </div>}
                            <div className="center mt-5">
                                <Excel data={updatedList} />
                                <ExcelResumen data={updatedList} fields={fieldsToExport} />
                            </div>
                        </div>
                        <div className="col-2 letra">
                            <div className="filtroTicketContainer pt-4 me-1" style={info.vista ? vista : novista}>
                                <div className="d-flex ps-3">
                                    <div className="">
                                        <button onClick={(e) => filtrar()} style={btn}><img src={iconoFiltrar} alt="filtrar" style={img} />FILTRAR</button>
                                    </div>
                                    <div className="">
                                        <button onClick={(e) => vaciar()} style={btn}><img src={iconoGlobal} alt="vaciar" style={img}></img>VACIAR</button>
                                    </div>
                                    {info.vista ?
                                        <div className="">
                                            <button style={btn} onClick={(e) => {
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    vista: false,
                                                }))
                                                setSortA({ nro: 0, patente: 0, pas: 0, cia: 0, cliente: 0, capital: 0, comp: 0, n: 0, fact: 0, cob: 0, cli: 0, cobro: 0, est: 0, cobrado: 0, stro: 0 })
                                            }
                                            }><img src={iconoCobro} style={img}></img>COBROS</button>
                                        </div> :
                                        <div>
                                            <button style={btn} onClick={(e) => {
                                                setInfo((prevState) => ({ ...prevState, vista: true, }))
                                                setSortA({ nro: 0, patente: 0, pas: 0, cia: 0, cliente: 0, capital: 0, comp: 0, n: 0, fact: 0, cob: 0, cli: 0, cobro: 0, est: 0, cobrado: 0, vto: 0 })
                                            }
                                            }><img src={iconoExpediente} alt="cobro" style={img}></img>EXPEDIENTES</button>
                                        </div>}
                                </div>
                                <form className="me-3 ms-3 mt-3" onKeyDown={(e) => { if (e.key === 'Enter') { filtrar() } }}>
                                    <div class="">
                                        <input
                                            placeholder="BUSQUEDA GENERAL"
                                            type="text"
                                            class="inputsDelFiltroSearch pe-3"
                                            onChange={(e) =>
                                                setInfo((prevState) => ({
                                                    ...prevState,
                                                    info: e.target.value,
                                                }))
                                            }
                                            value={info.info}
                                        />
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6 pe-1">
                                            <h6 className="subtituloDelFiltro m-0">Asignado</h6>
                                            <MultiSelect
                                                options={asignadoInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                value={info.asignado}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        asignado: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 ps-1">
                                            <h6 className="subtituloDelFiltro m-0">Estado</h6>
                                            <MultiSelect
                                                options={estadoInput}
                                                value={info.estado}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        estado: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6 pe-1">
                                            <h6 className="subtituloDelFiltro m-0">Nivel</h6>
                                            <MultiSelect
                                                className=""
                                                options={nivelInput}
                                                value={info.nivel}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        nivel: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 ps-1">
                                            <h6 className="subtituloDelFiltro m-0">Vencimiento</h6>
                                            <MultiSelect
                                                className=""
                                                options={fechaInput}
                                                value={info.fecha}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        fecha: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6 pe-1">
                                            <h6 className="subtituloDelFiltro m-0">Compañias</h6>
                                            <MultiSelect
                                                className=""
                                                options={compInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                value={info.comp}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        comp: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <div className="col-6 ps-1">
                                            <h6 className="subtituloDelFiltro m-0">Productores</h6>
                                            <MultiSelect
                                                className=""
                                                options={proInput.sort((a, b) => a.label.localeCompare(b.label))}
                                                value={info.pas}
                                                onChange={(e) => {
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        pas: e
                                                    }))
                                                }}
                                            />
                                        </div>
                                    </div>
                                </form>
                                <div className="me-3 ms-3">
                                    <div className="mt-2">
                                        <h6 className="subtituloDelFiltro m-0">Mes Cobrado</h6>
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <input type="month" class="inputsDelFiltro" value={info.mesCobradoFrom}
                                                    placeholder={infoTabla.tablaGerencia.mesCobradoFrom}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            mesCobradoFrom: e.target.value,
                                                        }))} />
                                            </div>
                                            <div className="col-6 ps-1">
                                                <input type="month" class="inputsDelFiltro" value={info.mesCobradoTo} placeholder={infoTabla.tablaGerencia.mesCobradoTo}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            mesCobradoTo: e.target.value,
                                                        }))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <h6 className="subtituloDelFiltro m-0">Mes Estimado</h6>
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <input type="month" class="inputsDelFiltro" value={info.mesEstimadoFrom} placeholder={infoTabla.tablaGerencia.mesEstimadoFrom}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            mesEstimadoFrom: e.target.value,
                                                        }))} />
                                            </div>
                                            <div className="col-6 ps-1">
                                                <input type="month" class="inputsDelFiltro" value={info.mesEstimadoTo} placeholder={infoTabla.tablaGerencia.mesCobradoTo}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            mesEstimadoTo: e.target.value,
                                                        }))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <h6 className="subtituloDelFiltro m-0">Fecha Exp</h6>
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <input type="date" class="inputsDelFiltro" value={info.expFrom} placeholder={infoTabla.tablaGerencia.expFrom}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            expFrom: e.target.value,
                                                        }))} />
                                            </div>
                                            <div className="col-6 ps-1">
                                                <input type="date" class="inputsDelFiltro" value={info.expTo} placeholder={infoTabla.tablaGerencia.expTo}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            expTo: e.target.value,
                                                        }))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <h6 className="subtituloDelFiltro m-0">Nro</h6>
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <input type="number" class="inputsDelFiltro" value={info.nroFrom} placeholder={infoTabla.tablaGerencia.nroFrom}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            nroFrom: e.target.value,
                                                        }))} />
                                            </div>
                                            <div className="col-6 ps-1">
                                                <input type="number" class="inputsDelFiltro" value={info.nroTo} placeholder={infoTabla.tablaGerencia.nroTo}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            nroTo: e.target.value,
                                                        }))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-2 row">
                                        <div className="col-6 pe-1">
                                            <h6 className="subtituloDelFiltro m-0">Modo de Pago</h6>
                                            <select
                                                className="inputsDelFiltro" value={info.modoDePago}
                                                onChange={(e) =>
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        modoDePago: e.target.value,
                                                    }))
                                                }
                                            >
                                                <option selected hidden>
                                                    {infoTabla.tablaGerencia.modoDePago}
                                                </option>
                                                <option value="">
                                                    {" "}
                                                </option>
                                                {pagoInput.map((comp) => (
                                                    <option key={comp} value={comp}>
                                                        {comp}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col-6 ps-1">
                                            <h6 className="subtituloDelFiltro m-0">Estado Cobro</h6>
                                            <select
                                                className="inputsDelFiltro" value={info.estadoCobro}
                                                onChange={(e) =>
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        estadoCobro: e.target.value,
                                                    }))
                                                }
                                            >
                                                <option selected hidden>
                                                    {infoTabla.tablaGerencia.estadoCobro}
                                                </option>
                                                <option value="" >
                                                    {" "}
                                                </option>
                                                {estadoCobroInput.map((pro) => (
                                                    <option key={pro} value={pro}>
                                                        {pro}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <div className="col-6 pe-1">
                                            <h6 className="subtituloDelFiltro m-0">Nro Factura</h6>
                                            <input type="number" class="inputsDelFiltro" value={info.nroFactura} placeholder={infoTabla.tablaGerencia.nroFactura}
                                                onChange={(e) =>
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        nroFactura: e.target.value,
                                                    }))} />
                                        </div>
                                        <div className="col-6 ps-1">
                                            <h6 className="subtituloDelFiltro m-0">Facturado Por</h6>
                                            <select
                                                className="w-100 inputsDelFiltro" value={info.facturadoPor}
                                                onChange={(e) =>
                                                    setInfo((prevState) => ({
                                                        ...prevState,
                                                        facturadoPor: e.target.value,
                                                    }))
                                                }
                                            >
                                                <option selected hidden>
                                                    {infoTabla.tablaGerencia.facturadoPor}
                                                </option>
                                                <option value="">
                                                    {" "}
                                                </option>
                                                {facturadoInput.map((pro) => (
                                                    <option key={pro} value={pro}>
                                                        {pro}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="mt-2">
                                        <h6 className="subtituloDelFiltro m-0">Hnrios Facturados</h6>
                                        <div className="row">
                                            <div className="col-6 pe-1">
                                                <input type="number" class="inputsDelFiltro" value={info.honorariosFrom} placeholder={infoTabla.tablaGerencia.honorariosFrom}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            honorariosFrom: e.target.value,
                                                        }))} />
                                            </div>
                                            <div className="col-6 ps-1">
                                                <input type="number" class="inputsDelFiltro" value={info.honorariosTo} placeholder={infoTabla.tablaGerencia.honorariosTo}
                                                    onChange={(e) =>
                                                        setInfo((prevState) => ({
                                                            ...prevState,
                                                            honorariosTo: e.target.value,
                                                        }))} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 center">
                                        <ReactPaginate
                                            className='center p-0'
                                            forcePage={currentPage}
                                            activeClassName={info.vista ? 'item active' : 'item active text-white'}
                                            breakClassName={info.vista ? 'item break-me' : 'item break-me text-white'}
                                            breakLabel={'...'}
                                            marginPagesDisplayed={1}
                                            onPageChange={handlePageClick}
                                            pageClassName={info.vista ? 'item pagination-page' : 'item pagination-page text-white'}
                                            pageRangeDisplayed={5}
                                            pageCount={pageCount}
                                        />
                                    </div>
                                    <h6 className={info.vista ? 'center' : 'center text-white'}>Cantidad de expedientes: {updatedList.length}</h6>
                                </div>
                            </div>
                        </div>
                    </section>
                </main >}*/
            }
        </>
    );
}
